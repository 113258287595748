import React from 'react';
import './styles/DayNightToggle.css';

function DayNightToggle({defaultChecked, setToggleFn}) {

	return (
		<div className="toggleWrapper">
			<input defaultChecked={defaultChecked} type="checkbox" className="dn" id="dn"
			       onChange={e=> setToggleFn(e.target.checked)}/>
			<label htmlFor="dn" className="toggle">
			    <span className="toggle__handler">
			      <span className="crater crater--1"></span>
			      <span className="crater crater--2"></span>
			      <span className="crater crater--3"></span>
			    </span>
				<span className="star star--1"></span>
				<span className="star star--2"></span>
				<span className="star star--3"></span>
				<span className="star star--4"></span>
				<span className="star star--5"></span>
				<span className="star star--6"></span>
			</label>
		</div>
	);
}

export default DayNightToggle;
