class Constants {
	static mainUrl = 'https://myresidence.shop/';
	// noinspection JSUnusedLocalSymbols
	static v1 = 'api/v1/'
	// noinspection JSUnusedLocalSymbols
	static v2 = 'api/v2/'
	static v3 = 'api/v3/'

	static API = this.mainUrl + this.v3;

	static DEFAULT_USER_IMAGE = this.mainUrl + 'public/pictures/user/default.jpeg';
	static DEFAULT_ITEM_IMAGE = this.mainUrl + 'public/pictures/item/default.jpeg';
	static DEFAULT_COMMUNITY_IMAGE = this.mainUrl + 'public/pictures/community/default.webp';

	static SUPPORTED_COUNTRIES =
		{
			'AU': 'Australia',
			'IN': 'India',
			'MY': 'Malaysia',
			'GB': 'United Kingdom',
			// 'US': 'United States',
			'CA': 'Canada',
			'SG': 'Singapore',
			'AE': 'UAE'
		};

	static SUPPORTED_CURRENCIES = {
		'AU': '$',
		'IN': '₹',
		'MY': 'RM',
		'GB': '£',
		'US': '$',
		'CA': '$',
		'SG': '$',
		'AE': 'AED'
	}

	static ITEM_LISTING_TYPES = ['Product', 'Rental', 'Service'];

	static GET_CURRENCY = (country) => {
		if (!country) return '';
		return Constants.SUPPORTED_CURRENCIES[country.toUpperCase()]
	}
}

export default Constants;