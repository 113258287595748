import React from "react";
import {Button, Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";

function About({communities}) {

	return (
		<Container>
			<h1 className="my-3">Greetings!</h1>
			<p>
				Student Market is an initiative managed by students and alumni. We believe we have a
				solution to improve the lives of communities by providing them with a platform to exchange goods and
				services without the hassle of third-party payments or shipping, simultaneously reducing waste and
				promoting sustainability.
				<br/>
				Also, stay tuned with our latest updates by following us on
				<Button className="mx-2" href="https://www.instagram.com/the.student.market/" target="_blank"
				        rel="noopener noreferrer">
					Instagram <FontAwesomeIcon icon={faInstagram}/>
				</Button>
			</p>

			<h3>Our Mission</h3>
			<div className="quote-box my-3 p-3">
				Got items in good shape you don’t need anymore?
			</div>
			<p>
				We cater primarily to students transitioning out of their dorms or residences. We understand that moving
				can be overwhelming, especially when deciding what to do with your belongings. The Student Market offers
				a platform to sell items you no longer need, turning potential waste into valuable assets for others,
				all while providing affordable options.
			</p>

			<h3>Supporting Student Communities</h3>
			<div className="quote-box my-3 p-3">
				We're constantly looking to grow and include more communities.
			</div>
			{/*<p>
				At the moment, we're serving these awesome student communities:
			</p>
			<ul>
				{
					Object.values(communities).map((r) => {
						const label = r.community_full_name;
						return (
							<li key={label}>{label}</li>
						)
					})
				}
			</ul>*/}
			<p>
				If your University or Community isn't on our list yet, please hold off on transactions for now. We aim
				to keep our
				platform safe and trustworthy for everyone involved.
				<br/>
				<br/>
				If you want to include your community, please reach out to us on our Contacts page.
			</p>

		</Container>
	);
}

export default About;
