import React, {useEffect, useState} from "react";
import {Button, Container, Modal} from "react-bootstrap";
import axios from "axios";

import './styles/Promote.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ItemCardOld from "./ItemCardOld";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {DateRange} from 'react-date-range';
import Constants from "../classes/Constants";
import {faCircle, faCircleCheck} from "@fortawesome/free-solid-svg-icons";

function Promote({user}) {

	const [items, setItems] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [showPromoteSuccess, setShowPromoteSuccess] = useState(false);
	const [showPromoteModal, setShowPromoteModal] = useState(false);
	const [selectedPricing, setSelectedPricing] = useState(0);

	const [dateSelector, setDateSelector] = useState([
		{
			startDate: new Date().setDate(new Date().getDate() + 1),
			endDate: new Date(new Date().setDate(new Date().getDate() + 3)),
			key: 'selection'
		}
	]);

	const navigate = useNavigate();

	useEffect(() => {
		if (user.user_id) {
			loadItems();
		}
	}, [])


	async function loadItems() {
		const data = {
			action: 'user',
			userId: user.user_id
		}

		const response = await axios.post(Constants.API + 'item.php', data);
		const status = response.data.status;
		const result = response.data.result;

		if (!status) {
			return;
		}

		setItems(result);
	}

	const promoteItem = (item) => {
		setSelectedItem(item);
		setShowPromoteModal(true);
	}

	const submitPromotion = async () => {
		closeModal();
		const data = {
			action: 'promote',
			session: user.session,
			itemId: selectedItem.item_id,
			startingDate: new Date(dateSelector[0].startDate).toISOString(),
			endingDate: new Date(dateSelector[0].endDate).toISOString(),
			price: calculatePrice()
		}

		const response = await axios.post(Constants.API + 'item.php', data);

		if (response.data.status) {
			setShowPromoteSuccess(true);
		}
	}

	const closeModal = () => {
		setShowPromoteModal(false);
		setShowPromoteSuccess(false);
	}

	const pricingTable = (title, price, duration, options) => {
		return (
			<div className="p-card mb-5 mb-lg-0 p-4 shadow-lg">
				<div className="card-body">
					<h5 className="p-card-title text-muted text-uppercase text-center">
						{title}
					</h5>
					<h6 className="p-card-price text-center">
						{price}
						<span className="period">
								/{duration}
							</span></h6>
					<hr/>
					<ul className="fa-ul">
						{
							options.map(
								option =>
									<li className={option[0] ? '' : 'text-muted'}>
										{
											option[0]
												?
												<span className="fa-li"><FontAwesomeIcon
													icon={faCircleCheck}/></span>
												:
												<span className="fa-li"><FontAwesomeIcon
													icon={faCircle}/></span>
										}
										{option[1]}
									</li>
							)
						}
					</ul>
				</div>
			</div>
		);
	}

	const pricingTitles = ['Free', 'Standard', 'Premium']
	const pricingTables = [
		pricingTable(pricingTitles[0], 'RM 0', '3 days', [
			[true, 'Per Listing, One-Time use'],
			[false, 'Waiting period of 2 Days'],
			[false, 'Replaced for a higher tier'],
			[false, 'Standard Ranking'],
		]),
		pricingTable(pricingTitles[1], 'RM 2', 'day', [
			[true, 'Per Listing, Unlimited use'],
			[true, 'Instantly Featured'],
			[true, 'Listing will stay until expired'],
			[false, 'Standard Ranking'],
		]),
		pricingTable(pricingTitles[2], 'RM 40', 'month', [
			[true, 'Per Listing, Unlimited use'],
			[true, 'Instantly Featured'],
			[true, 'Listing will stay until expired'],
			[true, 'Highest Ranking'],
		])
	]


	const calculateDays = () => {
		const startDate = new Date(dateSelector[0].startDate);
		const endDate = new Date(dateSelector[0].endDate);

		// Calculate the difference in days
		return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
	}


	/**
	 * Calculates the price based on the given conditions:
	 * - First 3 days are free.
	 * - Every individual day costs $2.
	 * - For every 30-day period (which includes the first 3 free days), the cost is $40.
	 *
	 * @returns {number} - Returns the calculated price
	 */
	const calculatePrice = () => {

		const differenceInDays = calculateDays();

		if (differenceInDays < 0) return -1;

		// First 3 days are free
		let freeDays = 3;
		let daysAfterFree = differenceInDays - freeDays;

		let price;
		// Calculate price for less than or equal to 30 days
		if (differenceInDays <= 30) {
			price = daysAfterFree > 0 ? daysAfterFree * 2 : 0;
		} else {
			// Calculate price for more than 30 days
			let fullBlocks = Math.floor(differenceInDays / 30);
			let remainderDays = differenceInDays % 30 - freeDays;
			remainderDays = remainderDays > 0 ? remainderDays : 0;
			price = fullBlocks * 40 + remainderDays * 2;
		}

		return price;
	}


	return (
		<Container>

			<h1>Promote your Products</h1>
			<p>
				Maintaining this service ain't cheap. We make minimal profits and whatever we earn is reinvested into
				providing this service to you. We help empower communities and promote a sustainable goal.
				<br/>
				<br/>
				If you like what we do, consider supporting us. For a small fee, we will feature your product
				on the front page. This will help us cover our costs and keep this service running.
				<br/>
				<br/>
				For other ways to support us, consider
				<Button className="mx-2" variant='outline-secondary' onClick={() => navigate('/volunteer')}>
					Volunteering
				</Button>
			</p>

			<section className="pricing my-5">
				<h2>Pricing</h2>
				<div className="selectors py-3">
					{
						pricingTitles.map((price, index) =>
							<button
								className={'shadow selector-button' + (index === selectedPricing ? ' selected' : '')}
								onClick={() => setSelectedPricing(index)}>
								{price}
							</button>
						)
					}
				</div>
				<div className="container">
					<div className="row">
						{
							pricingTables.map((price, index) =>
								<div className={"col-lg-4 " + (index === selectedPricing ? '' : 'unselected')}>
									{price}
								</div>
							)
						}
					</div>
				</div>

				<p>Subject to <Button variant='outline-secondary' onClick={() => navigate('/tnc')}>Terms and
					Conditions</Button></p>

			</section>


			{
				!user || !user.user_id ?
					<div className="quote-box my-3 p-3">
						You need to have an account to get started.
					</div>
					:
					items.length === 0 ?
						<div className="quote-box my-3 p-3">
							You need to have a product listed to get started.
						</div>
						:
						<div>
							<h2>Select the product you want to promote</h2>
							<div className="product-display">
								{
									items.map((i) => <ItemCardOld item={i} promoteFn={promoteItem}/>)
								}
							</div>
						</div>
			}


			<Modal show={showPromoteModal} onHide={closeModal}>
				<Modal.Header closeButton>
					<Modal.Title>Customize your featured product</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Product: <b>{selectedItem.item_title}</b>
					<div className="my-3">
						<DateRange
							minDate={new Date()}
							editableDateInputs={true}
							onChange={i => setDateSelector([i.selection])}
							moveRangeOnFirstSelection={false}
							ranges={dateSelector}
						/>
					</div>

					<div className="d-flex gap-3 mb-3">
						<div>
							Duration: {calculateDays()} Days
						</div>
						<div>
							Price: <b>{calculatePrice() < 0 ? '...' : calculatePrice() === 0 ? 'FREE' : 'RM ' + calculatePrice()}</b>
						</div>
					</div>

					The Free tier is not indicative of the above selected dates and can be subjected to a waiting list.
					<br/>
					If you continue, you agree to the following:
					<ul>
						<li>You agree to be contacted by us for payment or confirmation purposes</li>
						<li>Payment, if required, is non-refundable</li>
					</ul>
					More information can be found in our <Button variant='outline-light'
					                                             onClick={() => navigate('/tnc')}>Terms and
					Conditions</Button>


				</Modal.Body>
				<Modal.Footer style={{background: 'var(--primary-color)'}}>
					<Button onClick={() => setShowPromoteModal(false)} variant="secondary">
						Cancel
					</Button>
					<Button onClick={submitPromotion} variant="primary">
						Agree and Continue
					</Button>
				</Modal.Footer>
			</Modal>


			<Modal show={showPromoteSuccess} onHide={closeModal}>
				<Modal.Header closeButton>
					<Modal.Title>Let's get your product featured</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					We have received your request!
					We may contact you for payment or confirmation purposes.
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={closeModal} variant="primary">
						Continue
					</Button>
				</Modal.Footer>
			</Modal>

		</Container>
	);
}

export default Promote;