import React from "react";
import {Container} from "react-bootstrap";

function Contact() {

	return (
		<Container>
			<h1 className="my-3">Hello There <span role="img" aria-label="waving">👋</span></h1>
			<p>
				You can send an email to <a href="mailto:admin@student.market" target="_blank"
				                            rel="noopener noreferrer">admin@student.market</a><br/>
				Or chat with us on <a href="https://www.instagram.com/the.student.market/" target="_blank"
				                      rel="noopener noreferrer">Instagram</a>
			</p>
			<p>The Student Market is an independent venture run by students and not part of any Residence or University
				Management</p>
		</Container>);
}

export default Contact;