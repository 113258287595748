// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCpW22Rp-tKwD4_5yN69L46nJNWSU-VPEs",
	authDomain: "student-market-119fa.firebaseapp.com",
	projectId: "student-market-119fa",
	storageBucket: "student-market-119fa.appspot.com",
	messagingSenderId: "492937084161",
	appId: "1:492937084161:web:fff031645e03082d37af00",
	measurementId: "G-NVV482KP46"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics