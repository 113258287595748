import React, {useState} from "react";
import {Button, Container, Form, Modal} from "react-bootstrap";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Constants from "../classes/Constants";

function CommunityRequest({}) {

	const countries = [
		{code: 'MY', name: 'Malaysia'},
		{code: 'SG', name: 'Singapore'},
		{code: 'AU', name: 'Australia'},
		{code: 'IN', name: 'India'},
		{code: 'AE', name: 'United Arab Emirates'},
		{code: 'US', name: 'United States'},
		{code: 'GB', name: 'United Kingdom'},
		{code: 'CA', name: 'Canada'},
		{code: 'NZ', name: 'New Zealand'},
		{code: 'PH', name: 'Philippines'},
		{code: 'ID', name: 'Indonesia'},
		{code: 'TH', name: 'Thailand'},
		{code: 'VN', name: 'Vietnam'},
		{code: 'HK', name: 'Hong Kong'},
		{code: 'TW', name: 'Taiwan'},
		{code: 'KR', name: 'South Korea'},
		{code: 'JP', name: 'Japan'},
		{code: 'CN', name: 'China'},
		{code: 'LK', name: 'Sri Lanka'},
		{code: 'BD', name: 'Bangladesh'},
		{code: '-1', name: 'Other'},
	]
	const [formData, setFormData] = useState({
		action: 'request',
		name: '',
		type: '',
		country: '',
		address: '',
	});

	const types = ['Residence', 'University', 'University Hostel', 'Other']

	const [showSuccess, setShowSuccess] = useState(false);
	const navigate = useNavigate();


	const handleInputChange = event => {
		const {name, value} = event.target;
		setFormData(prevData => ({
			...prevData,
			[name]: value,
		}));
	};

	async function submitForm(e) {
		e.preventDefault();
		setShowSuccess(true);
		await axios.post(Constants.API + 'community.php', formData);
	}

	return (
		<Container>
			<h1>Request a Community</h1>
			<p>Don't see your community? Request it here!</p>

			<Form method='post' onSubmit={submitForm} className='my-5' style={{maxWidth: '500px'}}>
				<Form.Group className="mb-3">
					<Form.Label>Community Name</Form.Label>
					<Form.Control id='name' name='name' type='text' placeholder='Community Name'
					              onChange={handleInputChange}
					              maxLength={50}
					              required/>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Community Type</Form.Label>
					<Form.Select id='type' name='type'
					             onChange={handleInputChange}
					             required>
						<option value=''>Select the type of Community</option>
						{types.map((type, index) => (
							<option key={index} value={type}>{type}</option>
						))}
					</Form.Select>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Country</Form.Label>
					<Form.Select id='country' name='country'
					             onChange={handleInputChange}
					             required>
						<option value=''>Select a Country</option>
						{countries.map((country, index) => (
							<option key={index} value={country.code}>{country.name}</option>
						))}
					</Form.Select>
					<Form.Text className="text-muted">
						Currently we are only available in Malaysia
					</Form.Text>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Address</Form.Label>
					<Form.Control id='address' name='address' type='text' placeholder='Address'
					              maxLength={120}
					              onChange={handleInputChange}/>
					<Form.Text className="text-muted">
						Specify the country if you selected "Other"
					</Form.Text>
				</Form.Group>

				<Button variant="primary" type="submit">
					Submit
				</Button>
			</Form>


			<Modal show={showSuccess} onHide={() => setShowSuccess(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						Your request has been submitted
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					We will check out your community and check if it is suitable for our platform.
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => navigate('/')}>
						Continue
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	)

}

export default CommunityRequest;