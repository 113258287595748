import React, {useState} from 'react';
import './styles/AnimatedArrow.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert, Button, Container, Form} from "react-bootstrap";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {ProgressBar} from "react-loader-spinner";
import Checkbox from "./Checkbox";
import Constants from "../classes/Constants";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

/**
 * Handles the registration page
 * @param api
 * @param user
 * @param loginCallback
 */
function Register({user, loginCallback}) {

	const navigate = useNavigate();

	if (user.user_id !== undefined) {
		navigate('/dashboard');
	}

	const [previewUrl, setPreviewUrl] = useState(Constants.DEFAULT_USER_IMAGE);
	const [isLoading, setIsLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [agreed, setAgreed] = useState(false);
	const [formData, setFormData] = useState({
		profilePicture: null,
		username: '',
		password: '',
		name: '',
	})


	const previewProfileImage = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			return
		}

		const file = e.target.files[0];
		const objectUrl = URL.createObjectURL(file);
		setFormData({...formData, profilePicture: file})
		setPreviewUrl(objectUrl);
	}


	const handleInputChange = event => {
		const {name, value} = event.target;
		setFormData(prevData => ({
			...prevData,
			[name]: value,
		}));
	};


	async function handleSubmit(e) {
		e.preventDefault();
		clearErrors();
		if (isLoading) return;

		const target = e.target;

		// check if username meets length requirements
		const username = formData.username.trim();
		if (username.length > 20 || username.length < 5) {
			target[1].classList.add('is-invalid');
			setAlertMessage('Username needs to be between 5 to 20 characters');
			return;
		}

		// Check if username has invalid characters
		const usernameRegex = /^[a-zA-Z0-9]+$/;
		if (!username.match(usernameRegex)) {
			target[1].classList.add('is-invalid');
			setAlertMessage('Username must only contain invalid characters or numbers');
			return;
		}

		// Check if username is unique
		let data = {
			action: 'uniqueUsername',
			username
		};
		const resultUsername = await axios.post(Constants.API + 'user.php', data);

		if (!resultUsername.data.status) {
			target[1].classList.add('is-invalid');
			setAlertMessage('Username is already in use');
			return;
		}

		// Check if password meets requirements
		if (formData.password.length < 5) {
			target[2].classList.add('is-invalid');
			setAlertMessage('Password is too short');
			return;
		}

		if (!agreed) {
			setAlertMessage("You must agree to our privacy policy");
			return;
		}

		data = new FormData();
		data.append('action', 'register');
		data.append('profilePicture', formData.profilePicture);
		data.append('username', username);
		data.append('password', formData.password);
		data.append('name', formData.name.trim());

		setIsLoading(true);
		const result = await axios.post(Constants.API + 'user.php', data,
			{headers: {'Content-Type': 'multipart/form-data'}});
		setIsLoading(false);

		// A successful registration will automatically login server side
		if (result.data.status) {
			loginCallback(result.data.result);
			navigate('/account/contact')
		} else {
			setAlertMessage(result.data.result);
		}
	}


	function clearErrors() {
		const formElems = document.getElementsByClassName('form-control');
		for (const e of formElems) {
			e.classList.remove('is-invalid');
		}

		setAlertMessage('');
	}

	return (
		<Container style={{maxWidth: '500px', margin: 'auto'}}>
			<div className={'header-box shadow p-4'}>
				<h2>Register</h2><br/>
				You need to register before you can sell or post on Forums<br/>
				Your data will be stored securely and in accordance to our privacy policy<br/><br/>
				<small>Already have an account?</small>
				<Button className='ms-3' variant={"outline-primary"} onClick={() => navigate('/login')}>
					<FontAwesomeIcon className='left-arrow' icon={faAngleLeft} size={"xl"}/>
					Login
				</Button>

				<Form method='post' onSubmit={handleSubmit} className='my-5'>
					<Form.Group className="mb-3">
						<Form.Label>Upload your profile picture</Form.Label>
						<Form.Control onChange={previewProfileImage} name='profilePicture'
						              type="file" accept="image/*"/>
						<img className='picture-preview mt-5' src={previewUrl} alt='Dashboard preview'/>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Username</Form.Label>
						<Form.Control name='username' type="text" placeholder="5-20 characters" required
						              onChange={handleInputChange}/>
						<Form.Text className="text-muted">
							Your username must be unique
						</Form.Text>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Password</Form.Label>
						<Form.Control name='password' type="password" placeholder="5-50 characters" required
						              onChange={handleInputChange}/>
						<Form.Text className="text-muted">
							Your password will be encrypted
						</Form.Text>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Name</Form.Label>
						<Form.Control name='name' type="text" placeholder="2-20 characters" required
						              onChange={handleInputChange}/>
						<Form.Text className="text-muted">
							Your name will be displayed publicly
						</Form.Text>
					</Form.Group>

					<Checkbox checked={false} onChangeFn={setAgreed} label='I agree to the privacy policy'/>

					<ProgressBar
						height="50"
						width="60"
						ariaLabel="progress-bar-loading"
						wrapperStyle={{}}
						wrapperClass="progress-bar-wrapper"
						borderColor='#F4442E'
						barColor='#51E5FF'
						visible={isLoading}
					/>
					<br/>

					<Button variant="primary" type="submit">
						Submit
					</Button>
				</Form>
				{
					alertMessage === '' ? '' : (
						<Alert variant='danger'>
							{alertMessage}
						</Alert>
					)
				}
			</div>
		</Container>
	)
}

export default Register;