import React, {Suspense, useEffect, useState} from "react";
import {Button, Container, Dropdown, InputGroup, Modal} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ProgressBar} from "react-loader-spinner";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";

import './styles/Market.css';
import CommunitySelector from "./CommunitySelector";
import Constants from "../classes/Constants";
import ItemPage from "./ItemPage";
import {faBars, faCircleXmark, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

const ItemCard = React.lazy(() => import('./ItemCard'));

function Market({user, location}) {

	const {item_id} = useParams();
	const navigate = useNavigate();

	// Sort and search
	const sortList = ['Newest', 'Oldest', 'Lowest Price', 'Highest Price'];
	const [sort, setSort] = useState(0);
	const [searchInput, setSearchInput] = useState('');

	// Community selection
	const [selectedCommunities, setSelectedCommunities] = useState(new Set([]));
	const [showCommunitySelection, setShowCommunitySelection] = useState(false);

	// Item management
	const [items, setItems] = useState([]);
	const [featuredItems, setFeaturedItems] = useState([]);
	const [showFeatured, setShowFeatured] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	// Popups and Modals
	const [showInstagramPromotion, setShowInstagramPromotion] = useState(false);


	useEffect(() => {
		loadInstagramPromotion();
	}, [])

	useEffect(() => {
		if (searchInput.length === 0 || searchInput.length > 1) {
			loadItems();
		}
	}, [searchInput, sort]);

	useEffect(() => {
		if (selectedCommunities.size > 0) {
			loadItems();
			// loadFeaturedSection();
		} else {
			setItems([]);
		}
	}, [selectedCommunities]);


	/**
	 * Retrieves items based on selected communities, search box and sort filters
	 */
	async function loadItems() {
		if (selectedCommunities.size === 0) {
			return;
		}

		const data = {
			action: 'all',
			communities: Array.from(selectedCommunities).join(','),
			search: searchInput,
			sort: sort,
			priceStart: 0,
			priceEnd: 10000,
		};

		setIsLoading(true);
		const response = await axios.post(Constants.API + 'item.php', data);
		setIsLoading(false);
		const status = response.data.status;
		const result = response.data.result;

		if (!status) {
			console.error("Could not retrieve items", response.data);
			return;
		}

		for (const item of result) {
			// Convert date to milliseconds
			item.item_list_date = Date.parse(item.item_list_date);

			// Price is string with comma seperated thousands
			// Remove the decimal places
			if (item.item_price.slice(-3) === '.00') {
				item.item_price = item.item_price.slice(0, -3);
			}

			// Convert communities to integers
			let _communities = []
			for (const _community of item.communities) {
				_communities.push(parseInt(_community));
			}
			item.communities = _communities;
		}

		setItems(result);
	}

	async function loadFeaturedSection() {
		const data = {
			action: 'featured',
			communities: Array.from(selectedCommunities).join(','),
		};
		const response = await axios.post(Constants.API + 'item.php', data);
		const status = response.data.status;
		const result = response.data.result;

		if (!status) {
			console.error("Could not retrieve featured items", response.data);
			return;
		}

		setShowFeatured(result.length === 0);
		setFeaturedItems(result)
	}

	/**
	 * Show Instagram promotion every N times the user visits the site
	 */
	function loadInstagramPromotion() {
		const N = 20;
		const key = 'instagramPromotion';
		let instagramPromotion = localStorage.getItem(key);
		if (instagramPromotion === null) {
			localStorage.setItem(key, '0')
			return;
		} else {
			instagramPromotion = parseInt(instagramPromotion);
		}

		if (instagramPromotion % N === 0) {
			instagramPromotion = 0;
			setShowInstagramPromotion(true);
		}
		instagramPromotion += 1;
		localStorage.setItem(key, `${instagramPromotion}`);
	}

	const closeModal = () => {
		setShowInstagramPromotion(false);
	}

	const goToInstagram = () => {
		closeModal();
		window.open('https://www.instagram.com/the.student.market/');
	}

	function submitCommunitySelection(selections) {
		setSelectedCommunities(selections);
		setShowCommunitySelection(selections.size === 0);
	}

	const sellItemsPage = () => {
		navigate('/product/new');
	}

	if (item_id)
		return <ItemPage user={user}/>

	return <Container>

		<div className="mb-4">
			<div className="mb-4">
				<h1 className='text-lg-start fw-bold'>STUDENT MARKET</h1>
				<small>
					Empowering student communities
				</small>
			</div>
			<div className="d-flex">
				<Button className="me-3" variant="primary" onClick={sellItemsPage}>
					Start Selling
				</Button>
				<Button variant="outline-secondary" onClick={() => setShowCommunitySelection(!showCommunitySelection)}>
					Select your Community {
					showCommunitySelection
						?
						<FontAwesomeIcon icon={faCircleXmark}/>
						: <FontAwesomeIcon icon={faBars}/>
				}
				</Button>
			</div>
		</div>

		{
			selectedCommunities.size === 0 &&
			<div className="p-3 text-center fw-bold m-1">
				FIRST: Select your community
			</div>
		}

		<CommunitySelector
			user={user}
			selectedCommunities={selectedCommunities}
			folded={!showCommunitySelection}
			location={location}
			showSelectAll={true}
			submitFn={submitCommunitySelection}/>

		{/*<div className={"featured-section shadow-lg" + (showFeatured ? " folded" : "")}>
			<h3 className="title mb-3">Featured
				<Hearts
					height="40"
					width="40"
					color="var(--selection-color)"
					ariaLabel="hearts-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/>
				<Button style={{margin: "auto 20px auto auto"}} variant="outline-secondary"
				        onClick={() => setShowFeatured(!showFeatured)}>
					<FontAwesomeIcon icon={showFeatured ? solid("arrow-down") : solid("arrow-up")}/>
				</Button>
			</h3>
			<div className="scrollable-view">
				<div className="horizontal-scroll">
					{
						featuredItems.map((item, index) => {
							return <ItemCard key={index} item={item}/>;
						})
					}
					{
						user
							?
							<EmptyCard/>
							:
							''
					}
				</div>
			</div>
		</div>*/}


		<div className="header-box shadow-lg my-3">
			<div className='d-flex flex-row'>
				<InputGroup className="me-3">
					<InputGroup.Text id="search-input">
						<FontAwesomeIcon icon={faMagnifyingGlass}/>
					</InputGroup.Text>
					<Form.Control
						placeholder="Search for a Product"
						aria-label="Search"
						aria-describedby="search-input"
						onChange={e => setSearchInput(e.target.value)}
					/>
				</InputGroup>

				<Dropdown>
					<Dropdown.Toggle variant="secondary" id="filter-dropdown">
						{sortList[sort]}
					</Dropdown.Toggle>

					<Dropdown.Menu>
						{sortList.map((f, i) => {
							return <Dropdown.Item key={i} onClick={() => setSort(i)}>{f}</Dropdown.Item>
						})}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</div>

		<div className="my-5">

			{
				isLoading &&
				<div className="d-flex justify-content-center mb-3">
					<ProgressBar
						height="50"
						width="60"
						ariaLabel="progress-bar-loading"
						wrapperClass="progress-bar-wrapper"
						borderColor='#F4442E'
						barColor='#51E5FF'
						visible={isLoading}
					/>
				</div>
			}

			<div style={{margin: '20px auto', color: 'indianred', textAlign: 'center'}}>
				{
					selectedCommunities.size == 0
						?
						'Select a Community to display items'
						:
						items.length === 0 && !isLoading
							? searchInput.length > 1
								? 'Item not found!'
								: 'Selected Communities do not have items listed'
							:
							''
				}
			</div>

			{
				<div className="product-display">
					<Suspense fallback={<div>Loading...</div>}>
						{
							items.map((item, i) => (
								<ItemCard key={i} item={item}/>
							))
						}
					</Suspense>
				</div>
			}

		</div>

		<Modal show={showInstagramPromotion} onHide={closeModal}>
			<Modal.Header closeButton>
				<Modal.Title>
					Check out our Instagram page
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Follow use on Instagram to support us and keep up with the latest updates
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => closeModal()}>
					Close
				</Button>
				<Button onClick={() => goToInstagram()}>
					Instagram <FontAwesomeIcon icon={faInstagram}/>
				</Button>
			</Modal.Footer>
		</Modal>

	</Container>;
}

export default Market;