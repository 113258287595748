import React, {useEffect, useState} from 'react';
import {Link, Route, Routes, useNavigate} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Cookies from 'js-cookie';
import axios from "axios";

import './App.css';
import logo from './images/logo.png'

import analytics from './Firebase';
import {logEvent} from "firebase/analytics";
import About from './components/About';
import NewItem from './components/NewItem';
import Dashboard from './components/Dashboard';
import Help from './components/Help';
import Login from './components/Login';
import Register from './components/Register';
import EditProfile from './components/EditProfile';
import Market from './components/Market';
import Invalid from './components/Invalid';
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import Promote from "./components/Promote";
import EditItem from "./components/EditItem";
import TnC from "./components/TnC";
import DayNightToggle from "./components/DayNightToggle";
import EditContact from "./components/EditContact";
import Volunteer from "./components/Volunteer";
import CommunityRequest from "./components/CommunityRequest";
import {ProgressBar} from "react-loader-spinner";
import Constants from "./classes/Constants";

function App() {

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [currentUser, setCurrentUser] = useState({});
	const [location, setLocation] = useState({});
	const [darkTheme, setDarkTheme] = useState(localStorage.getItem('darkTheme') === '1');


	function getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	}

	useEffect(() => {
		// Migrate session token from old site
		const sessionToken = new URLSearchParams(window.location.search).get('session');
		if (sessionToken) {
			setSession(sessionToken);
			navigate('/');
		}

		cookieLogin()
			.then(_ => getLocation())
			.then(_ => setIsLoading(false))
			.catch(_ => setHasError(true));

		logEvent(analytics, 'page_view', {page_title: 'Homepage'});
	}, []);


	/**
	 * Set the user's location and currency as the selected community region
	 */
	useEffect(() => {
		getLocation()
			.catch(_ => setHasError(true));
	}, [currentUser]);


	/**
	 * Set the theme of the website
	 */
	useEffect(() => {
		if (darkTheme) {
			localStorage.setItem('darkTheme', '1');
			document.documentElement.setAttribute('data-theme', 'dark');
		} else {
			localStorage.setItem('darkTheme', '0');
			document.documentElement.removeAttribute('data-theme');
		}
	}, [darkTheme]);


	/**
	 * Set the session cookie
	 * @param session
	 */
	function setSession(session) {
		Cookies.set('session', session, {expires: 1000});
	}


	/**
	 * Attempt to log in through cookie session
	 */
	async function cookieLogin() {
		const session = Cookies.get('session');
		const data = {action: "cookieLogin", session};
		const response = await axios.post(Constants.API + 'user.php', data);
		const status = response.data.status;
		const result = response.data.result;
		if (!status) {
			return;
		}

		login(result, false);
	}


	/**
	 * Get user's location
	 * @returns {Promise<void>}
	 */
	async function getLocation() {
		let _location = location;

		// Get location, country and currency from IP address
		if (!location.country) {
			const response = await axios.get('https://ipapi.co/json/');
			const data = response.data;
			_location = {
				...location,
				lat: data.latitude,
				lng: data.longitude,
				currency: data.currency,
				country: data.country,
				country_name: data.country_name
			}
		}

		// If user is logged in, set the location to their selected community.
		// Selected community location can be different from user's location
		if (currentUser.user_id && currentUser.communities.length > 0) {
			const communityId = currentUser.communities[0];
			const data = {action: 'one', communityId};
			const response = await axios.post(Constants.API + 'community.php', data);
			const status = response.data.status;
			const result = response.data.result;
			const communityCountry = result.community_country.toUpperCase();

			// Only change if the user is in a different country from their selected community
			if (status && communityCountry !== location.country) {
				_location = {
					..._location,
					lat: result.community_latitude,
					lng: result.community_longitude,
					country: communityCountry,
					currency: Constants.GET_CURRENCY(communityCountry),
					country_name: Constants.SUPPORTED_COUNTRIES[communityCountry],
				}
			}
		}

		setLocation(_location);
	}


	/**
	 * Authentication is successful and save the user session to cookie
	 * @param user User object
	 * @param navigateToDash Whether to navigate to the dashboard after login
	 */
	function login(user, navigateToDash = true) {

		// Parse user community IDs to integers
		let _communities = [];
		for (const _community of user.communities) {
			const communityId = parseInt(_community);
			if (isNaN(communityId)) {
				continue;
			}
			_communities.push(communityId);
		}
		user.communities = _communities;

		setSession(user.session);
		setCurrentUser(user);

		if (navigateToDash) navigate('/dashboard');
	}

	/**
	 * User log out
	 */
	function logout() {
		setCurrentUser({});
		Cookies.remove('session');
	}


	return (
		isLoading
			?
			<div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
				{
					hasError ?
						<div>
							<h1>Something went wrong</h1>
							<p>Please check back later...</p>
						</div>
						:
						<ProgressBar
							height="50"
							width="60"
							ariaLabel="progress-bar-loading"
							wrapperStyle={{}}
							wrapperClass="progress-bar-wrapper"
							borderColor='#F4442E'
							barColor='#51E5FF'
							visible={isLoading}
						/>
				}
			</div>
			:
			<div className="app">

				<Navbar variant={darkTheme ? "dark" : "light"} expand="sm">

					<Container>
						<Navbar.Brand as={Link} to={'/'}>
							<img
								src={logo}
								width="35"
								height="40"
								className="d-inline-block align-top"
								alt="Logo"
							/>
						</Navbar.Brand>

						<DayNightToggle defaultChecked={darkTheme} setToggleFn={setDarkTheme}/>

						<Navbar.Toggle aria-controls="basic-navbar-nav"/>
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav variant='pills' className="me-auto">
								<Nav.Link as={Link} to={'/'}>Market</Nav.Link>
								{/*<Nav.Link as={Link} to={'/forum'}>Forum</Nav.Link>*/}
								<Nav.Link as={Link} to={'/dashboard'}>Dashboard</Nav.Link>
								<Nav.Link as={Link} to={'/promote'}>Promote</Nav.Link>
								<NavDropdown title="Support" id="basic-nav-dropdown">
									<NavDropdown.Item as={Link} to={'/about'}>About</NavDropdown.Item>
									<NavDropdown.Item as={Link} to={'/contact'}>Contact</NavDropdown.Item>
									<NavDropdown.Item as={Link} to={'/volunteer'}>Volunteer</NavDropdown.Item>
									<NavDropdown.Divider/>
									<NavDropdown.Item as={Link} to={'/privacy'}>Privacy Policy</NavDropdown.Item>
									<NavDropdown.Item as={Link} to={'/tnc'}>Terms and Conditions</NavDropdown.Item>
								</NavDropdown>
							</Nav>
						</Navbar.Collapse>

					</Container>
				</Navbar>

				<div className='my-2'/>

				<Routes>
					<Route path='/' element={<Market location={location}
					                                 user={currentUser}/>}/>
					<Route path='/market' element={<Market location={location}
					                                       user={currentUser}/>}/>
					{/*<Route path='/forum' element={<Forum communities={communities}
				                                     user={currentUser}/>}/>*/}
					<Route path='/dashboard' element={<Dashboard user={currentUser}
					                                             location={location}
					                                             logoutFn={logout}/>}/>
					<Route path='/login' element={<Login user={currentUser} loginCallback={login}/>}/>
					<Route path='/register' element={<Register user={currentUser} loginCallback={login}/>}/>
					<Route path='/account/modify' element={<EditProfile user={currentUser}/>}/>
					<Route path='/account/contact' element={<EditContact user={currentUser}/>}/>
					<Route path='/product/new' element={<NewItem user={currentUser} location={location}/>}/>
					<Route path='/product/:item_id' element={<Market location={location} user={currentUser}/>}/>
					<Route path='/product/modify/:item_id' element={<EditItem user={currentUser}/>}/>
					<Route path='/requestCommunity' element={<CommunityRequest/>}/>
					<Route path='/promote' element={<Promote user={currentUser}/>}/>
					<Route path='/volunteer' element={<Volunteer/>}/>
					<Route path='/help' element={<Help/>}/>
					<Route path='/about' element={<About/>}/>
					<Route path='/contact' element={<Contact/>}/>
					<Route path='/privacy' element={<Privacy/>}/>
					<Route path='/tnc' element={<TnC/>}/>
					<Route path='*' element={<Invalid/>}/>
				</Routes>

				<footer className="mt-5">
					<div className="copyright-text">
						<p>Copyright &copy; 2023<br/>All Right Reserved</p>
					</div>
					<div className="footer-menu">
						<ul>
							<li><Link to="/contact">Contact</Link></li>
							<li><Link to="/about">About</Link></li>
							<li><Link to="/privacy">Privacy Policy</Link></li>
							<li><Link to="/tnc">Terms and Conditions</Link></li>
						</ul>
					</div>
				</footer>
			</div>
	);
}

export default App;
