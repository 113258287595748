import React from "react";
import './styles/UserCard.css';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faTelegram, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import {faLink} from "@fortawesome/free-solid-svg-icons";

function UserCard({user, loggedInUser}) {

	function contactWhatsapp() {
		const name = encodeURIComponent(user.user_full_name);
		const title = encodeURIComponent(user.item_title);
		const whatsapp = user.user_whatsapp;
		window.open('https://api.whatsapp.com/send?phone=' + whatsapp + '&text=Hello%20' + name + '%2C%20I%20am%20interested%20in%20purchasing%20the%20item%20' + title + '%20you%20have%20listed%20on%20the%20Student%20Market%20site.', '_blank');
	}

	function contactTelegram() {
		const telegram = user.user_telegram
		window.open('https://t.me/' + telegram, '_blank');
	}

	function contactInstagram() {
		const instagram = user.user_instagram;
		window.open('https://www.instagram.com/' + instagram);
	}

	function goToCustomUrl() {
		const url = user.user_custom_url;
		window.open(url, '_blank');
	}

	return (
		<div className="profile-card">
			<div className="profile-header">
				<div className="image-wrap">
					<img src={user.picture_url} alt="User Dashboard"/>
				</div>
				<div className="title">
					<h2 className="fw-bold">
						{user.user_full_name}
					</h2>
					<small>
						Selling
						in {user.communities.length} {user.communities.length == 1 ? 'community' : 'communities'}
					</small>
				</div>
			</div>

			{
				user.user_privacy === 2
					?
					<div className="profile-contact">
						The user has hidden their contact details.
					</div>
					:
					user.user_privacy === 1 && !loggedInUser.user_id
						?
						<div className="profile-contact">
							You must be logged in to view the user's contact details.
						</div>
						:
						<div className="profile-contact">
							{
								user.user_whatsapp &&
								<Button onClick={contactWhatsapp} variant="primary">
									<FontAwesomeIcon icon={faWhatsapp}/> Whatsapp
								</Button>
							}
							{
								user.user_instagram &&
								<Button onClick={contactInstagram} variant="primary">
									<FontAwesomeIcon icon={faInstagram}/> Instagram
								</Button>
							}
							{
								user.user_telegram &&
								<Button onClick={contactTelegram} variant="primary">
									<FontAwesomeIcon icon={faTelegram}/> Telegram
								</Button>
							}
							{
								user.user_custom_url &&
								<Button onClick={goToCustomUrl} variant="primary">
									<FontAwesomeIcon icon={faLink}/> Custom Link
								</Button>
							}

						</div>
			}

		</div>
	)
}

export default UserCard;