import React from "react";
import {Container} from "react-bootstrap";

function About() {

	return (<Container>

		<ol>

			<h1>Terms and Conditions for <b>Student Market</b></h1>
			<small>Last Updated: 09/08/2023</small>

			<hr/>
			<p>
				Welcome to Student Market! These Terms and Conditions govern your use of our website and services. By
				accessing or using Student Market (referred to as "we," "our," or "us"), you agree to comply with and
				be bound by these Terms and Conditions. If you do not agree with these terms, please do not use our
				website.
			</p>

			<h3>
				<li>Acceptance of Terms:</li>
			</h3>
			<p>
				By accessing or using Student Market, you agree to these Terms and Conditions and our Privacy Policy.
				If you do not agree, you may not use our services.
			</p>

			<h3>
				<li>Account Registration:</li>
			</h3>
			<p>
				<ol style={{listStyleType: 'lower-alpha'}}>
					<li>To use certain features of Student Market, you may need to create an account.</li>
					<li>You must provide accurate, complete, and up-to-date information during registration.</li>
					<li>You are responsible for all activities that occur under your account.
					</li>
				</ol>
			</p>

			<h3>
				<li>Pricing Structure:</li>
			</h3>
			<p>
				Student Market offers various optional plans for enhancing your seller experience. By using these plans,
				you agree to the following:
				<ol style={{listStyleType: 'lower-alpha'}}>
					<li>You agree to be contacted by us for payment or confirmation purposes.</li>
					<li>Prices are subject to change and will be communicated to users in advance.</li>
					<li>One Day is marked starting from 12:00 am to 11:59 pm of the same day.</li>
					<li>One Month refers to 31 Days.</li>
					<li>Payment, if required, is non-refundable.</li>
					<li>Payment, if required, should be made BEFORE the product is featured.</li>
					<li>You agree to pay all fees associated with your selected plan.</li>
				</ol>
			</p>

			<h3>
				<li>User Conduct:</li>
			</h3>
			<p>
				<ol style={{listStyleType: 'lower-alpha'}}>
					<li>You agree to use Student Market for lawful purposes only.</li>
					<li>You may not use the website to transmit any harmful, illegal, or unethical content.</li>
					<li>You are solely responsible for your interactions with other users both online and in-person.
					</li>
				</ol>
			</p>

			<h3>
				<li>Intellectual Property:</li>
			</h3>
			<p>
				<ol style={{listStyleType: 'lower-alpha'}}>
					<li>All content on Student Market, including text, graphics, logos, and images, is protected by
						copyright and other intellectual property laws.
					</li>
					<li>You may not use, reproduce, or distribute any content from our website without our explicit
						consent.
					</li>
				</ol>
			</p>

			<h3>
				<li>Termination:</li>
			</h3>
			<p>
				<ol style={{listStyleType: 'lower-alpha'}}>
					<li>We reserves the right to terminate or suspend your account at its discretion.</li>
					<li>You may terminate your account at any time by sending us an email.</li>
				</ol>
			</p>

			<h3>
				<li>Limitation of Liability:</li>
			</h3>
			<p>
				<ol style={{listStyleType: 'lower-alpha'}}>
					<li>Student Market is provided "as is" without any warranties, express or implied.</li>
					<li>We are not liable for any direct, indirect, incidental, or consequential damages arising from
						your use of our website.
					</li>
				</ol>
			</p>

			<h3>
				<li>Indemnification:</li>
			</h3>
			<p>
				You agree to indemnify and hold Student Market, its affiliates, and employees harmless from any
				claims, losses, or damages arising out of your use of the website or violation of these Terms and
				Conditions.
			</p>

			<h3>
				<li>Governing Law:</li>
			</h3>
			<p>
				These Terms and Conditions are governed by and construed in accordance with the laws of Malaysia.
				Any legal action related to these terms shall be brought in the courts located in Malaysia.
			</p>

			<h3>
				<li>Changes to Terms:</li>
			</h3>
			<p>
				We may update these Terms and Conditions at any time. Your continued use of Student Market after
				changes are posted constitutes your acceptance of the revised terms.

				Please review our <a href='/privacy'>Privacy Policy</a> to understand how we collect, use, and protect
				your personal
				information.

				If you have any questions or concerns about these Terms and Conditions, please contact us.

				By using Student Market, you acknowledge that you have read, understood, and agree to these Terms and
				Conditions.
			</p>

		</ol>

		<br/>
		<br/>

	</Container>)
}

export default About;