import React from "react";
import {Container} from "react-bootstrap";

function About() {

	return (<Container>

		<div>

			<h1>Privacy Policy for <b>Student Market</b></h1>
			<small>Last Updated: 01/09/2023</small>

			<hr/>

			<p>At <b>Student Market</b>, accessible from <b>www.student.market</b> and <b>www.myresidence.shop</b>, one
				of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of
				information that is collected and recorded by <b>Student Market</b> and how we use it.</p>


			<p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with
				regards to the information that they shared and/or collect in <b>Student Market</b>. This policy
				is not applicable to any information collected offline or via channels other than this website.</p>

			<h2>Consent</h2>

			<p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

			<h2>Information we collect</h2>

			<p>The personal information that you are asked to provide, and the reasons why you are asked to provide it,
				will be made clear to you at the point we ask you to provide your personal information.
				When you register for an Account, we may ask for your contact information.</p>

			<h2>How we use your information</h2>

			<p>We use the information we collect in various ways, including to:</p>

			<ul>
				<li>Provide, operate, and maintain our website</li>
				<li>Understand and analyze how you use our website</li>
				<li>Develop new features and functionality</li>
				<li>Contact you regarding promotions or changes to the website</li>
				<li>Find and prevent fraud</li>
			</ul>

			<h2>We do <b>NOT</b> use your information for the following purposes</h2>

			<ul>
				<li>Sell or share any information we collect with third-party advertisers.</li>
			</ul>

			<p>
				Do note that your contact details may be accessible to other users through this website.
				You can customize your visibility in your Dashboard.
			</p>

			<h2>Log Files</h2>

			<p><b>Student Market</b> follows a standard procedure of using log files. These files log visitors
				when
				they visit websites. All hosting companies do this and a part of hosting services' analytics. The
				information collected by log files include internet protocol (IP) addresses, browser type, Internet
				Service
				Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are
				not
				linked to any information that is personally identifiable. The purpose of the information is for
				analyzing
				trends, administering the site, tracking users' movement on the website, and gathering demographic
				information.</p>

			<h2>Cookies Policy</h2>

			<p>This website uses cookies to store information including
				visitors' preferences, and the pages on the website that the visitor accessed or visited. The
				information is used to optimize the users' experience by customizing our web page content based on
				visitors' browser type and/or other information.</p>


			<h2>Our Advertising Partners</h2>

			<p>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed
				below.
				Each of our advertising partners has their own Privacy Policy for their policies on user data. For
				easier
				access, we hyperlinked to their Privacy Policies below.</p>

			<ul>
				<li>
					<p>Google</p>
					<p><a
						href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>
					</p>
				</li>
			</ul>

			<h2>Advertising Partners Privacy Policies</h2>

			<p>You may consult this list to find the Privacy Policy for each of the advertising partners of <b>Residence
				Market</b>.</p>

			<p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are
				used in their respective advertisements and links that appear on <b>Student Market</b>, which are
				sent directly to users' browser. They automatically receive your IP address when this occurs. These
				technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize
				the
				advertising content that you see on websites that you visit.</p>

			<p>Note that <b>Student Market</b> has no access to or control over these cookies that are used by
				third-party advertisers.</p>

			<h2>Third Party Privacy Policies</h2>

			<p><b>Student Market</b>'s Privacy Policy does not apply to other advertisers or websites. Thus, we
				are
				advising you to consult the respective Privacy Policies of these third-party ad servers for more
				detailed
				information. It may include their practices and instructions about how to opt-out of certain options.
			</p>

			<p>You can choose to disable cookies through your individual browser options. To know more detailed
				information
				about cookie management with specific web browsers, it can be found at the browsers' respective
				websites.</p>

			<h2>GDPR Data Protection Rights</h2>

			<p>We would like to make sure you are fully aware of all of your data protection rights. Every user is
				entitled
				to the following:</p>
			<p>The right to access – You have the right to request copies of your personal data. We may charge you a
				small
				fee for this service.</p>
			<p>The right to rectification – You have the right to request that we correct any information you believe is
				inaccurate. You also have the right to request that we complete the information you believe is
				incomplete.</p>
			<p>The right to erasure – You have the right to request that we erase your personal data, under certain
				conditions.</p>
			<p>The right to restrict processing – You have the right to request that we restrict the processing of your
				personal data, under certain conditions.</p>
			<p>The right to object to processing – You have the right to object to our processing of your personal data,
				under certain conditions.</p>
			<p>The right to data portability – You have the right to request that we transfer the data that we have
				collected to another organization, or directly to you, under certain conditions.</p>
			<p>If you make a request, we have one month to respond to you. If you would like to exercise any of these
				rights, please contact us.</p>

			<h2>Children's Information</h2>

			<p>Another part of our priority is adding protection for children while using the internet. We encourage
				parents
				and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

			<p><b>Student Market</b> does not knowingly collect any Personal Identifiable Information from
				children
				under the age of 13. If you think that your child provided this kind of information on our website, we
				strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such
				information from our records.</p>

			<h2>Contact Us</h2>
			<p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us
				at <a href='mailto:admin@student.market'>admin@student.market</a>.</p>


			<br/>
			<br/>

		</div>
	</Container>)
}

export default About;