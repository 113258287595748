import React, {useState} from 'react';
import './styles/ItemCard.css';
import {Badge} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import Constants from "../classes/Constants";

function ItemCard({item}) {

	const listingTypes = Constants.ITEM_LISTING_TYPES;
	const navigate = useNavigate();
	const placeholder = 'https://via.placeholder.com/150x200.png?text=Loading+Image';
	const [dpLoaded, setDpLoaded] = useState(false);

	function handleClick() {
		navigate(`/product/${item.item_id}`);
	}

	return (
		<Link to={'/product/' + item.item_id} className="item-card shadow">
			<div className="image-container">
				<Badge className="item-price">
					<sup>{Constants.GET_CURRENCY(item.community_country)} </sup>
					{item.item_price}
				</Badge>
				{
					item.item_list_type > 0 &&
					<Badge className="item-type" bg="secondary">{listingTypes[item.item_list_type]}</Badge>
				}
				{
					!dpLoaded &&
					<img src={placeholder} alt={item.item_title} className="item-image"/>
				}
				<img
					src={item.sm_pictures && item.sm_pictures.length > 0 ? item.sm_pictures[0] : placeholder}
					// src={item.pictures && item.pictures.length > 0 ? item.pictures[0] : placeholder}
					style={{display: dpLoaded ? 'block' : 'none'}}
					onLoad={() => setDpLoaded(true)}
					alt={item.item_title} className="item-image"/>
			</div>
			<div className="item-details">
				<div className="item-title fw-bold">{item.item_title}</div>
				<div className="item-description">{item.item_description}</div>
			</div>
		</Link>
	);
}

export default ItemCard;
