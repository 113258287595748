import React, {useEffect, useState} from 'react';
import {Alert, Button, Container, Form} from "react-bootstrap";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {ProgressBar} from "react-loader-spinner";
import Constants from "../classes/Constants";

function EditProfile({user}) {

	const navigate = useNavigate();
	useEffect(() => {
		if (user.user_id) {
			setPreviewUrl(user.picture_url);
		} else {
			navigate('/');
		}
	}, []);

	const [previewUrl, setPreviewUrl] = useState(Constants.DEFAULT_USER_IMAGE);
	const [isLoading, setIsLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [formData, setFormData] = useState({
		profilePicture: null,
		oldPassword: '',
		newPassword: '',
		name: user.user_full_name,
	});

	const previewProfileImage = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			return
		}

		const file = e.target.files[0];
		const objectUrl = URL.createObjectURL(file);
		setFormData({...formData, profilePicture: file})
		setPreviewUrl(objectUrl);
	}

	const handleInputChange = event => {
		const {name, value} = event.target;
		setFormData(prevData => ({
			...prevData,
			[name]: value,
		}));
	};

	async function handleSubmit(e) {
		e.preventDefault();
		setAlertMessage('');
		if (isLoading) return;

		const data = new FormData();
		data.append('action', 'modify');
		data.append('session', user.session);
		if (formData.profilePicture !== null)
			data.append('profilePicture', formData.profilePicture);
		data.append('oldPassword', formData.oldPassword);
		data.append('newPassword', formData.newPassword);
		data.append('name', formData.name.trim());

		setIsLoading(true);
		const response = await axios.post(Constants.API + 'user.php', data,
			{headers: {'Content-Type': 'multipart/form-data'}});
		setIsLoading(false);

		const status = response.data.status;
		const result = response.data.result;

		if (status) {
			window.location.href = '/dashboard';
		} else {
			setAlertMessage(result);
		}
	}

	return (
		<Container>
			<div className='header-box shadow'>
				<h2>Modify Profile</h2>
				<br/>
				<small>Change your profile details</small>
				<Button variant='secondary' className='float-end' onClick={() => navigate('/account/contact')}>
					Modify Contact
				</Button>
			</div>

			<Form method='post' onSubmit={handleSubmit} className='my-3 p-4 shadow header-box'>

				<Form.Group className="mb-3">
					<Form.Label>Change your profile picture</Form.Label>
					<Form.Control onChange={previewProfileImage} name='profilePicture'
					              type="file" accept="image/*"/>
					<img className='picture-preview mt-5' src={previewUrl} alt='Dashboard preview'/>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Username</Form.Label>
					<Form.Control disabled={true} name='username' type="text" defaultValue={user.user_username}/>
					<Form.Text className="text-muted">
						You cannot change username
					</Form.Text>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Name</Form.Label>
					<Form.Control name='name' type="text" defaultValue={user.user_full_name}
					              onChange={handleInputChange}/>
					<Form.Text className="text-muted">
						Your name will be displayed on your profile
					</Form.Text>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Current Password</Form.Label>
					<Form.Control name='oldPassword' type="password" placeholder="Current Password"
					              onChange={handleInputChange}/>
					<Form.Text className="text-muted">
						To change your password, enter your current password
					</Form.Text>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>New Password</Form.Label>
					<Form.Control name='newPassword' type="password" placeholder="New Password"
					              onChange={handleInputChange}/>
					<Form.Text className="text-muted">
						Enter your new password
					</Form.Text>
				</Form.Group>

				<ProgressBar
					height="50"
					width="60"
					ariaLabel="progress-bar-loading"
					wrapperStyle={{}}
					wrapperClass="progress-bar-wrapper"
					borderColor='#F4442E'
					barColor='#51E5FF'
					visible={isLoading}
				/>
				<br/>
				<Button variant="secondary" type="submit">
					Submit
				</Button>
			</Form>

			{
				alertMessage
					?
					<Alert variant='danger'>
						{alertMessage}
					</Alert>
					: ''
			}
		</Container>
	)
}

export default EditProfile;