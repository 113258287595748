import React, {useState} from "react";

import './styles/ItemCardOld.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {CirclesWithBar} from "react-loader-spinner";
import {faTrashCan, faWarning} from "@fortawesome/free-solid-svg-icons";

/**
 * The item card component
 * @param item Item object
 * @param communities Communities list
 * @param contactFn Contact function (set undefined to hide button)
 * @param reportFn Report function (set undefined to hide button)
 * @param removeFn Remove item function (set undefined to hide button)
 * @param editFn Edit the item function (set undefined to hide button)
 * @param promoteFn Promote the item (set undefined to hide button)
 * @returns {JSX.Element}
 * @constructor
 */
function ItemCardOld({item, contactFn, reportFn, removeFn, editFn, promoteFn}) {

	const [expanded, setExpanded] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const imageLoaded = () => {
		setIsLoading(false);
	};

	const expandCard = () => {
		setExpanded(!expanded)
	};

	return (
		<div className={"card shadow" + (expanded ? " expanded" : "") + (isLoading ? " blur" : "")}>
			<div className={"card-title"}>
				{item.item_title}
			</div>
			{
				isLoading && <div className="loader">
					<CirclesWithBar
						height="80"
						width="80"
						color="#4fa94d"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
						outerCircleColor="white"
						innerCircleColor="grey"
						barColor="white"
						ariaLabel='circles-with-bar-loading'
					/>
				</div>
			}
			<img onClick={expandCard} className={'card-image'}
			     src={item.pictures.length > 0 ? item.pictures[0] : ''}
			     alt={item.item_title}
			     onLoad={imageLoaded}
			/>
			<div className="card-user-image">
				<img
					src={item.picture_url}
					alt={item.user_full_name}
				/>
			</div>
			<div className="bottom-section">
				{
					<div className='custom-tags shadow item-price'>
						{item.item_price === 0 ? 'FREE' : 'RM ' + item.item_price}
					</div>
				}
			</div>
			<div className={'item-description'}>
				{item.item_description}
			</div>
			<div>
				{
					!contactFn ? '' : (
						<button type="button" className="card-button-long" onClick={() => contactFn(item)}>
							Contact
						</button>
					)
				}
				{
					!reportFn ? '' : (
						<button onClick={() => reportFn(item)} className={'card-button'}>
							<FontAwesomeIcon style={{width: '-webkit-fill-available'}} icon={faWarning}/>
						</button>
					)
				}
				{
					!removeFn ? '' : (
						<button onClick={() => removeFn(item)} className={'card-button'}>
							<FontAwesomeIcon style={{width: '-webkit-fill-available'}} icon={faTrashCan}/>
						</button>
					)
				}
				{
					!editFn ? '' : (
						<button onClick={() => editFn(item)} className="card-button-long">
							Modify
						</button>
					)
				}
				{
					!promoteFn ? '' : (
						<button type="button" className="card-button-long" onClick={() => promoteFn(item)}>
							Promote
						</button>
					)
				}
			</div>

		</div>
	);
}

export default ItemCardOld;