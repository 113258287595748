import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Container, Form, Row} from 'react-bootstrap';
import {ProgressBar} from "react-loader-spinner";
import axios from "axios";
import Constants from "../classes/Constants";


function RoleCard({role, setSelectedFn}) {
	return (
		<div className="mb-3">
			<Form.Check
				className="fw-bold"
				name={role.role_id}
				type="checkbox"
				label={role.role_title}
				onChange={(e) => setSelectedFn(e.target.checked)}
			/>
			<small>{role.role_description}</small>
		</div>
	);
}


function VolunteerApplication() {
	const [formData, setFormData] = useState({
		action: 'volunteer',
		name: '',
		email: '',
		phone: '',
		allowCall: false,
		allowWhatsapp: false,
		allowEmail: false,
		skills: '',
		motivation: ''
	});
	const [roles, setRoles] = useState([]);
	const [selectedRoles, setSelectedRoles] = useState(new Set([]));

	const [isLoading, setIsLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');


	useEffect(() => {
		retrieveVolunteerRoles();
	}, [])


	function retrieveVolunteerRoles() {
		axios.post(Constants.API + 'career.php', {action: 'allVolunteers'})
			.then(response => {
				const status = response.data.status;
				const result = response.data.result;
				if (status) setRoles(result);
			});
	}

	function handleInputChange(event) {
		const {name, value} = event.target;
		setFormData(prevData => ({
			...prevData,
			[name]: value,
		}));
	}

	function handleCheckboxChange(event) {
		const {name, checked} = event.target;
		setFormData(prevData => ({
			...prevData,
			[name]: checked,
		}));
	}

	async function handleSubmit(e) {
		e.preventDefault();
		setAlertMessage('');
		if (isLoading) return;

		const rolesText = [...selectedRoles].join(',');
		const _formData = {...formData, roles: rolesText};

		if (!_formData.allowCall && !_formData.allowWhatsapp && !_formData.allowEmail) {
			setAlertMessage('Give us at least one way to contact you.');
			return
		}

		setIsLoading(true);
		const response = await axios.post(Constants.API + 'career.php', _formData);
		setIsLoading(false);

		const status = response.data.status;
		const result = response.data.result;

		if (status) {
			setAlertMessage('Your application has been submitted. We will contact you soon.');
		} else {
			setAlertMessage(result);
		}
	}

	return (
		<Container>
			<div className='header-box shadow'>
				<h2>Volunteer</h2>
				<div>
					Student Market is an initiative managed by students and alumni. We believe we have a
					solution to improve the lives of communities by providing them with a platform to exchange goods and
					services without the hassle of third-party payments or shipping, simultaneously reducing waste and
					promoting sustainability. We're on the lookout for passionate individuals who share our vision.
					<br/>
					<br/>
					This is completely voluntary and we do not offer any allowance or benefits. We make minimal profit
					and all of it goes back into development and advertising.
					<br/>
					<br/>
					If you are interested in empowering your fellow students, work towards a sustainable environment and
					gain valuable experience while doing so, join us!
				</div>
			</div>


			<div className='my-3 p-4 shadow header-box'>
				<div className="mb-3">
					<h3>Available Roles</h3>
					<small>
						Select the roles you are interested in.
					</small>
				</div>
				<div>
					{
						[...roles].map(role => (
							role.role_hiring
								?
								<RoleCard role={role} setSelectedFn={(checked) => {
									checked
										?
										setSelectedRoles(prevRoles => new Set(prevRoles.add(role.role_title)))
										:
										setSelectedRoles(prevRoles => {
											prevRoles.delete(role.role_title);
											return new Set(prevRoles);
										})
								}}/>
								:
								''
						))
					}
				</div>
			</div>

			<Form onSubmit={handleSubmit} className='my-3 p-4 shadow header-box'>

				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={2}>
						Name
					</Form.Label>
					<Col sm={10}>
						<Form.Control
							name="name"
							type="text"
							placeholder="Enter your full name"
							onChange={handleInputChange}
							required
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={2}>
						Phone
					</Form.Label>
					<Col sm={10}>
						<Form.Control
							name="phone"
							type="text"
							placeholder="Enter your phone number"
							onChange={handleInputChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={2}>
						Email
					</Form.Label>
					<Col sm={10}>
						<Form.Control
							name="email"
							type="text"
							placeholder="@"
							onChange={handleInputChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column sm={2}>
					</Form.Label>
					<Col sm={10}>
						<Form.Check
							name="allowCall"
							type="checkbox"
							label="Allow us to call you"
							onChange={handleCheckboxChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column sm={2}>
					</Form.Label>
					<Col sm={10}>
						<Form.Check
							name='allowWhatsapp'
							type="checkbox"
							label="Allow us to Whatsapp you"
							onChange={handleCheckboxChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={2}>
					</Form.Label>
					<Col sm={10}>
						<Form.Check
							name='allowEmail'
							type="checkbox"
							label="Allow us to Email you"
							onChange={handleCheckboxChange}
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={2}>
						Skills
					</Form.Label>
					<Col sm={10}>
						<Form.Control
							name="skills"
							as="textarea"
							rows={3}
							placeholder="Tell us what you're good at"
							onChange={handleInputChange}
							required
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className="mb-3">
					<Form.Label column sm={2}>
						Motivation
					</Form.Label>
					<Col sm={10}>
						<Form.Control
							name="motivation"
							as="textarea"
							rows={3}
							placeholder="Why do you want to be part of our team?"
							onChange={handleInputChange}
							required
						/>
					</Col>
				</Form.Group>

				<small>
					The details entered on this page will not be linked to your account or shared with other
					users on this platform. These details will only be used to contact you regarding your volunteer
					application.
					<br/>
					<br/>
					If we do not contact you within 10 days of submitting this form, consider your application
					rejected. You are always welcome to apply again.
				</small>
				<br/>

				{
					alertMessage && <Alert>{alertMessage}</Alert>
				}

				<ProgressBar
					height="50"
					width="60"
					ariaLabel="progress-bar-loading"
					wrapperStyle={{}}
					wrapperClass="progress-bar-wrapper"
					borderColor='#F4442E'
					barColor='#51E5FF'
					visible={isLoading}
				/>
				<br/>
				<Button type="submit">
					Submit
				</Button>
			</Form>
		</Container>
	);
}

export default VolunteerApplication;
