import React, {useState} from 'react';
import './styles/AnimatedArrow.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert, Button, Container, Form} from "react-bootstrap";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Constants from "../classes/Constants";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

/**
 * Handles the login page
 * @param api
 * @param user
 * @param loginCallback Function that accepts the logged-in user as the argument
 */
function Login({user, loginCallback}) {

	const navigate = useNavigate();

	if (user.user_id !== undefined) {
		navigate('/dashboard');
	}

	const [isLoading, setIsLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	async function loginSubmit(e) {
		e.preventDefault();
		clearErrors();
		if (isLoading) return;

		const target = e.target;
		const username = target[0].value.trim();
		const password = target[1].value;

		const data = {
			action: 'login',
			username, password
		};

		setIsLoading(true);
		const response = await axios.post(Constants.API + 'user.php', data);
		setIsLoading(false);

		if (response.data.status) {
			loginCallback(response.data.result);
		} else {
			setAlertMessage('Invalid username or password!');
		}
	}

	function clearErrors() {
		const formElems = document.getElementsByClassName('form-control');
		for (const e of formElems) {
			e.classList.remove('is-invalid');
		}

		setAlertMessage('');
	}


	return (
		<Container>
			<div className={'header-box shadow p-4'} style={{maxWidth: '500px', margin: 'auto'}}>
				<h2>Login</h2><br/>
				<small>Don't have an account?</small>
				<Button variant={'outline-primary'} className='ms-3' onClick={() => navigate('/register')}>
					Register
					<FontAwesomeIcon className='right-arrow' icon={faAngleRight} size={"xl"}/>
				</Button>

				{
					alertMessage === '' ? '' : (
						<Alert className="mt-3" variant='danger'>
							{alertMessage}
						</Alert>
					)
				}

				<Form method='post' onSubmit={loginSubmit} className='my-4'>
					<Form.Group className="mb-3">
						<Form.Label>Username</Form.Label>
						<Form.Control id='username' name='username' type='text' placeholder='Username' required/>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Password</Form.Label>
						<Form.Control id='password' name='password' type="password" placeholder="Password" required/>
					</Form.Group>

					<Button variant="primary" type="submit">
						Submit
					</Button>
				</Form>

				<div className="mb-3">
					<h4 className="fw-bold">Forgot your password?</h4>
					<p>
						We do not verify emails or phone numbers for your privacy. If you forgot your password,
						please contact the Admin for account transfer or deletion.
					</p>
					<div className="d-flex">
						<a href='https://www.instagram.com/the.student.market?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='
						   target='_blank' rel='noreferrer'>
							<Button variant='outline-primary'>
								Chat on Instagram
							</Button>
						</a>
						<a href="mailto:admin@student.market" target='_blank' rel='noreferrer'>
							<Button variant='outline-primary' className='ms-3'>
								Send an Email
							</Button>
						</a>
					</div>
				</div>
			</div>
		</Container>
	)
}

export default Login;