import React, {useEffect, useState} from 'react';
import {Alert, Button, Container, Dropdown, Form, InputGroup} from "react-bootstrap";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {ProgressBar} from "react-loader-spinner";
import Constants from "../classes/Constants";

function NewItem({user, location}) {

	const navigate = useNavigate();

	const listingTypes = Constants.ITEM_LISTING_TYPES;
	const [alertMessage, setAlertMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({
		action: 'new',
		title: '',
		description: '',
		price: 0.0,
		type: 0
	});


	useEffect(() => {
		if (!user.user_id) {
			navigate('/register', {replace: true});
		}
		
		setFormData({...formData, session: user.session});
	}, []);

	const handleInputChange = event => {
		const {name, value} = event.target;
		setFormData(prevData => ({
			...prevData,
			[name]: value,
		}));
	};

	async function handleSubmit(e) {
		e.preventDefault();
		clearErrors();
		if (isLoading) return;

		const target = e.target;
		const titleElem = target[0];
		const descElem = target[1];
		const priceElem = target[2];


		const title = formData.title;
		const description = formData.description;
		const price = formData.price;

		// Check title
		if (title.length > 30) {
			titleElem.classList.add('is-invalid');
			setAlertMessage('Title is too long');
			return;
		}

		// Check description
		if (description.length > 200) {
			descElem.classList.add('is-invalid');
			setAlertMessage('Description is too long');
			return;
		}

		// Check price
		if (price > 10000) {
			priceElem.classList.add('is-invalid');
			setAlertMessage('Price is too... pricey');
			return;
		}
		if (price < 0) {
			priceElem.classList.add('is-invalid');
			setAlertMessage('Price cannot be negative');
			return;
		}

		setIsLoading(true);
		const response = await axios.post(Constants.API + 'item.php', formData);
		setIsLoading(false);

		const status = response.data.status;
		const itemId = response.data.result;

		if (status) {
			navigate('/product/modify/' + itemId);
		} else {
			setAlertMessage('Could not list your product! Try again later.')
		}
	}

	function clearErrors() {
		const formElems = document.getElementsByClassName('form-control');
		for (const e of formElems) {
			e.classList.remove('is-invalid');
		}

		setAlertMessage('');
	}

	return (
		user.user_id &&
		<Container>
			<div className='header-box shadow'>
				<h2>New Product</h2><br/>
				<small>Create a new product listing here</small>
			</div>

			<Form onSubmit={handleSubmit} className='my-3 p-4 shadow header-box'>
				<Form.Group className="mb-3">
					<Form.Label>Title</Form.Label>
					<Form.Control name='title' type="text" placeholder="<30 characters" required
					              onChange={handleInputChange}/>
					<Form.Text className="text-muted">
						Choose a small title
					</Form.Text>
				</Form.Group>

				<Form.Group className="mb-3">
					<Form.Label>Description</Form.Label>
					<Form.Control
						name='description'
						as="textarea"
						placeholder="<200 characters"
						style={{height: '100px'}}
						required
						onChange={handleInputChange}
					/>
					<Form.Text className="text-muted">
						Give a brief description
					</Form.Text>
				</Form.Group>

				<InputGroup className="mb-3">
					<InputGroup.Text>{Constants.GET_CURRENCY(location.country)}</InputGroup.Text>
					<Form.Control defaultValue={0} name='price' type="number" placeholder="Price" required
					              step="0.01"
					              onChange={handleInputChange}/>
				</InputGroup>

				<Form.Group className="mb-3">
					<Form.Label>Category</Form.Label>
					<Dropdown>
						<Dropdown.Toggle variant="secondary" id="dropdown-basic">
							{listingTypes[formData.type]}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{
								listingTypes.map((type, index) => (
									<Dropdown.Item
										key={index}
										onClick={() => setFormData({...formData, type: index})}
									>
										{type}
									</Dropdown.Item>
								))
							}
						</Dropdown.Menu>
					</Dropdown>
				</Form.Group>


				<ProgressBar
					height="50"
					width="60"
					ariaLabel="progress-bar-loading"
					wrapperStyle={{}}
					wrapperClass="progress-bar-wrapper"
					borderColor='#F4442E'
					barColor='#51E5FF'
					visible={isLoading}
				/>
				<br/>
				<Button type="submit">
					Submit
				</Button>


			</Form>

			{
				alertMessage === '' ? '' : (
					<Alert variant='danger'>
						{alertMessage}
					</Alert>
				)
			}
		</Container>
	)
}

export default NewItem;