import React, {useEffect, useState} from 'react';
import './styles/Community.css';
import {Button, Dropdown, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Constants from "../classes/Constants";
import {ProgressBar} from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import {faMagnifyingGlass, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";


function CommunitySelector({user, folded, location, submitFn, showSelectAll = false}) {

	const navigate = useNavigate();

	// Local storage key
	const communitySetKey = 'select_communities';
	const countries = Constants.SUPPORTED_COUNTRIES;

	// Messages
	const MaxCommunities = 10;
	const defaultMessage = "Select your communities and press SAVE";
	const notAvailableMessage = "We are not available in ";
	const noneSelectedMessage = "Please select at least one community";
	const allSelectedMessage = "You are viewing all communities in ";
	const maxCommunitiesMessage = "You can only save up to " + MaxCommunities + " communities";
	const [message, setMessage] = useState(defaultMessage);

	// Selected country
	const [selectedCountry, setSelectedCountry] = useState(null);
	// Previous selected country (to check if changed and prevent unnecessary API calls)
	let [previousCountry, setPreviousCountry] = useState(null);
	// Search input
	const [searchInput, setSearchInput] = useState('');
	// A set of community IDs
	const [selection, setSelection] = useState(new Set([]));
	// Check to see if any changes made (reduce server load)
	const [madeChanges, setMadeChanges] = useState(false);
	// Precise location from browser [lat, lng]
	const [preciseLocation, setPreciseLocation] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const [communities, setCommunities] = useState({});


	/**
	 * Load the communities selection from local storage
	 */
	useEffect(() => {
		let _selectedCommunities = new Set([]);

		// Get local data of selected communities
		let _communitiesData = [];
		try {
			_communitiesData = JSON.parse(localStorage.getItem(communitySetKey));
		} catch (e) {
			return;
		}

		if (_communitiesData && _communitiesData.length > 0) {
			_selectedCommunities = new Set(_communitiesData);
		}
		// If no local data, get the signed-in user's communities
		else if (user.communities && user.communities.length > 0) {
			_selectedCommunities = new Set(user.communities);
		}

		// Set the country as the first community's country
		if (_selectedCommunities.size > 0) {
			const communityId = Array.from(_selectedCommunities)[0];
			const data = {action: 'getCountry', communityId};
			axios.post(Constants.API + 'community.php', data)
				.then(response => {
					const status = response.data.status;
					const result = response.data.result;
					if (status) {
						setSelectedCountry(result.toUpperCase());
					}
				})
		}

		setSelectedCountry(location.country.toUpperCase());
		setSelection(_selectedCommunities);
		submitFn(_selectedCommunities);
	}, []);


	/**
	 * Submit the selection to the parent component
	 * Save the selection to local storage
	 */
	useEffect(() => {
		updateMessage();
	}, [selection]);


	/**
	 * Get communities from the server
	 * @param country
	 */
	useEffect(() => {
		getCommunities();
		updateMessage();
	}, [selectedCountry, location]);


	/**
	 * Update the message
	 */
	function updateMessage() {
		if (!countries.hasOwnProperty(selectedCountry)) {
			setMessage(notAvailableMessage + location.country_name);
		} else if (selection.size > 0 && selection.size === Object.keys(communities).length) {
			setMessage(allSelectedMessage + countries[selectedCountry] + " (Not Recommended)");
		} else if (selection.size >= MaxCommunities) {
			setMessage(maxCommunitiesMessage);
		} else if (selection.size === 0) {
			setMessage(noneSelectedMessage);
		} else {
			setMessage(defaultMessage);
		}
	}


	function selectNewCountry(country) {
		setSelectedCountry(country);
		setSelection(new Set([]));
	}

	function selectAll() {
		const _keys = new Set(Object.keys(communities));
		// Set as integers
		let _selection = new Set([]);
		_keys.forEach((v) => {
			_selection.add(parseInt(v));
		});
		setSelection(_selection);
	}

	function deselectAll() {
		const _selection = new Set([]);
		setSelection(_selection);
	}

	function onChange(communityId) {

		// Prevent selection of more than MaxCommunities
		if (selection.size >= MaxCommunities && !selection.has(communityId)) {
			return;
		}

		// Deselect all if all selected
		if (selection.size === Object.keys(communities).length) {
			deselectAll();
			return;
		}

		setMadeChanges(true);
		const updatedSet = new Set(selection);
		if (selection.has(communityId)) {
			updatedSet.delete(communityId);
		} else {
			updatedSet.add(communityId);
		}
		setSelection(updatedSet);
	}

	function handleSubmit() {
		if (selection.size === 0) {
			return;
		}

		if (selection.size > MaxCommunities) {
			submitFn(selection);
			return;
		}

		// Save to local storage
		localStorage.setItem(communitySetKey, JSON.stringify(Array.from(selection)));

		if (user.user_id && madeChanges) {
			setMadeChanges(false);

			const data = {
				action: 'communities',
				session: user.session,
				communities: Array.from(selection).join(','),
			}
			axios.post(Constants.API + 'user.php', data)
				.then(_ => {
					submitFn(selection);
				})
		} else submitFn(selection);
	}


	/**
	 * Get all Communities
	 * @returns {Promise<void>}
	 */
	async function getCommunities() {
		if (!selectedCountry || selectedCountry === previousCountry) return;

		if (!countries.hasOwnProperty(selectedCountry)) return;

		setPreviousCountry(selectedCountry);
		setIsLoading(true);
		const data = {action: 'local', country: selectedCountry};
		const response = await axios.post(Constants.API + 'community.php', data);
		const status = response.data.status;
		const result = response.data.result;
		setIsLoading(false);

		if (!status) {
			console.error("Could not retrieve communities", response.data);
			return;
		}

		const _communities = {};

		let lat = preciseLocation.lat || location.lat || 0;
		let lng = preciseLocation.lng || location.lng || 0;

		for (const c of result) {
			c.community_id = parseInt(c.community_id);
			c.community_latitude = parseFloat(c.community_latitude);
			c.community_longitude = parseFloat(c.community_longitude);
			c.distance = getDistance(lat, lng, c.community_latitude || 0, c.community_longitude || 0);

			_communities[c.community_id] = c;
		}
		setCommunities(_communities);
	}


	// noinspection JSUnusedLocalSymbols
	/**
	 * Get precise location from browser
	 */
	function getPreciseLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const _location = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					}
					setPreciseLocation(_location);
				},
				(error) => {
					if (error.code === error.PERMISSION_DENIED) {
						console.error("User denied the request for geolocation.");
					} else {
						console.error("Geolocation error: " + error.message);
					}
				}
			);
		} else {
			console.error("Geolocation is not supported by this browser.");
		}
	}

	/**
	 * Get the distance between two coordinates
	 * @param lat1 First latitude
	 * @param lon1 First longitude
	 * @param lat2 Second latitude
	 * @param lon2 Second longitude
	 * @returns {number} Distance in kilometers
	 */
	function getDistance(lat1, lon1, lat2, lon2) {
		const R = 6371; // Radius of the Earth in kilometers
		const dLat = deg2rad(lat2 - lat1);  // deg2rad below
		const dLon = deg2rad(lon2 - lon1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2)
		;
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		// Distance in km
		return R * c;
	}

	/**
	 * Convert degrees to radians
	 * @param deg Degrees
	 * @returns {number} Radians
	 */
	function deg2rad(deg) {
		return deg * (Math.PI / 180);
	}

	/**
	 * Crop a string to a certain length
	 * @param str
	 * @param num
	 * @returns {*|string}
	 */
	function cropString(str, num) {
		if (str.length <= num) {
			return str;
		}

		return str.slice(0, num) + '...';
	}

	return (
		<div
			className={"container p-3 community-selector" + (folded ? " fold" : "")}>
			<div style={{maxWidth: '400px'}} className="d-flex justify-content-between">
				<Dropdown>
					<Dropdown.Toggle variant="secondary" id="filter-dropdown">
						{countries[selectedCountry]}
					</Dropdown.Toggle>

					<Dropdown.Menu style={{maxHeight: '220px', overflowY: 'auto'}}>
						{
							Object.keys(countries).map((f, i) => {
									return (
										<Dropdown.Item key={i} onClick={() => selectNewCountry(f)}>
											{countries[f]}
										</Dropdown.Item>
									)
								}
							)
						}
					</Dropdown.Menu>
				</Dropdown>

				<Button variant="secondary" onClick={() => navigate('/requestCommunity')}>
					Request your community
				</Button>

			</div>

			<InputGroup  style={{maxWidth: '400px'}} className="my-3">
				<InputGroup.Text id="search-input">
					<FontAwesomeIcon icon={faMagnifyingGlass}/>
				</InputGroup.Text>
				<Form.Control
					placeholder="Search for your Community"
					aria-label="Search"
					aria-describedby="search-input"
					onChange={e => setSearchInput(e.target.value)}
				/>
			</InputGroup>

			<div className="scrollable-view">
				<div className="horizontal-scroll">
					<ProgressBar
						height="50"
						width="60"
						ariaLabel="progress-bar-loading"
						wrapperStyle={{}}
						wrapperClass="progress-bar-wrapper"
						borderColor='#F4442E'
						barColor='#51E5FF'
						visible={isLoading}
					/>

					{
						// sort based on distance
						communities &&
						(
							searchInput ?
								// If search input is not empty, filter based on search input
								Object.values(communities).filter((c) => {
									return c.community_full_name.toLowerCase().includes(searchInput.toLowerCase()) || c.community_address.toLowerCase().includes(searchInput.toLowerCase());
								}) :
								// Otherwise, sort based on distance
								Object.values(communities).sort((a, b) => {
										return a.distance - b.distance;
									}
								))
							.map((c) => {
								const label = cropString(c.community_full_name, 40);
								// Get the second last comma value
								const address = c.community_address.split(',').slice(-2)[0];
								const subtitle = cropString(address, 30);
								const id = c.community_id;
								const imgSrc = c.community_picture_url;
								const disabled = c.community_country.toUpperCase() !== selectedCountry;

								return (
									!disabled &&
									<CommunityCard
										key={id}
										id={id}
										imgSrc={imgSrc}
										title={label}
										subtitle={subtitle}
										checked={selection.has(id)}
										onChangeFn={() => onChange(id)}
									/>
								)
							})


					}

				</div>
			</div>

			<div className="d-flex flex-column gap-3 my-2">
				<div className="d-flex align-items-center gap-3 mx-3">
					<FontAwesomeIcon icon={faTriangleExclamation}/>
					<small>
						{message}
					</small>
				</div>


				<div style={{maxWidth: '400px'}} className="d-flex justify-content-between">
					{
						showSelectAll && (
							selection.size <= MaxCommunities ?
								<Button className="me-2" variant="secondary" onClick={selectAll}>
									Select All
								</Button>
								:
								<Button className="me-2" variant="secondary" onClick={deselectAll}>
									Deselect All
								</Button>
						)
					}

					<Button style={{width: '195px'}} variant="primary" onClick={handleSubmit}>
						SAVE
					</Button>
				</div>

			</div>

		</div>

	);
}

function CommunityCard({id, imgSrc, title, subtitle, disabled, checked, onChangeFn}) {
	const [isChecked, setIsChecked] = useState(checked);

	const toggleCheck = () => {
		if (!disabled) {
			setIsChecked(prevState => !prevState);
			onChangeFn(id);
		}
	};


	useEffect(() => {
		setIsChecked(checked);
	})

	return (
		<div className={`community-card shadow-sm ${isChecked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
		     onClick={toggleCheck}>
			<div className="card__input"></div>
			<div className="card__body">
				<div className="card__body-cover">
					<img className="card__body-cover-image" src={imgSrc || Constants.DEFAULT_COMMUNITY_IMAGE}
					     alt={title}/>
					<span className="card__body-cover-checkbox">
            <svg className="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </svg>
          </span>
				</div>
				<header className="card__body-header">
					<div className="card__body-header-subtitle">{subtitle}</div>
					<div className="card__body-header-title">{title}</div>
				</header>
			</div>
		</div>
	);
}

export default CommunitySelector;
