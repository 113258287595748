import React, {useEffect, useState} from 'react';
import {Button, Container, Modal, Toast} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import axios from "axios";
import CommunitySelector from "./CommunitySelector";
import ItemCard from "./ItemCard";
import Constants from "../classes/Constants";
import {faAngleDown, faCartPlus, faHeartCircleBolt, faUserPen} from "@fortawesome/free-solid-svg-icons";

function Dashboard({user, logoutFn, location}) {

	const navigate = useNavigate();
	const [showCommunitySaved, setShowCommunitySaved] = useState(false);

	useEffect(() => {
		if (!user.user_id) {
			setShowNotLogin(true)
			setTimeout(() => {
				navigate('/login', {replace: true});
			}, 1500);
		}
	}, []);

	const [items, setItems] = useState([]);
	const [showNotLogin, setShowNotLogin] = useState(false);


	useEffect(() => {
		loadItems();
	}, []);


	async function loadItems() {
		const data = {
			action: 'user',
			userId: user.user_id
		}

		const response = await axios.post(Constants.API + 'item.php', data);
		const status = response.data.status;
		const result = response.data.result;

		if (!status) {
			return;
		}

		setItems(result);
	}

	const logout = () => {
		logoutFn();
		navigate('/market');
	}

	const mainButtonStyle = {
		width: '100px',
		height: '100px',
		borderRadius: '20px',
	}

	const mainButtonText = {
		fontSize: '12px',
		marginTop: '5px'
	}

	const customAccordion = {
		background: 'var(--secondary-color)',
		margin: '20px 0',
		padding: '20px',
		borderRadius: '10px',
		overflow: 'hidden',
		// Change maxHeight to 60px
		maxHeight: '10000px',
		transition: 'max-height 0.5s ease-out'
	}

	const arrowStyle = {
		marginRight: '10px',
		// Change to rotate(0deg)
		transform: 'rotate(180deg)',
		transition: 'transform 0.5s ease'
	}

	const accordionBodyStyle = {
		marginTop: '10px',
		padding: '20px',
		borderRadius: '10px',
	}

	const profileContentStyle = {
		fontSize: '14px',
		borderBottom: '1px',
		borderStyle: 'none none solid none',
		color: 'var(--heading-color)',
		marginBottom: '20px',
		padding: '0 10px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end'
	}


	const toggleAccordion = (e) => {
		const target = e.target;
		if (!target.classList.contains('accordion')) return;
		if (target.style.maxHeight == '10000px') {
			target.style.maxHeight = '60px';
			target.firstChild.style.transform = 'rotate(0deg)'
		} else {
			target.style.maxHeight = '10000px';
			target.firstChild.style.transform = 'rotate(180deg)'
		}
	}

	const editProfile = () => {
		navigate('/account/modify');
	}

	const newItem = () => {
		navigate('/product/new');
	}

	const promote = () => {
		navigate('/promote');
	}

	const closeAllConfirmations = () => {
		setShowNotLogin(false);
	}


	return (
		<Container style={{maxWidth: '500px', margin: 'auto'}}>
			<div className='header-box shadow'>
				<Button style={{float: 'right'}} variant={"secondary"} onClick={logout}>
					Logout
				</Button>
				<h2>Dashboard</h2><br/>
				<small>Manage your profile and product listings</small>
			</div>

			<div style={{display: 'flex', justifyContent: 'space-between', margin: '30px 10px'}}>
				<Button variant={"outline-primary"} style={mainButtonStyle} onClick={editProfile}>
					<FontAwesomeIcon icon={faUserPen} size={"2xl"}/>
					<div style={mainButtonText}>Edit<br/>Profile</div>
				</Button>
				<Button variant={"outline-primary"} style={mainButtonStyle} onClick={newItem}>
					<FontAwesomeIcon icon={faCartPlus} size={"2xl"}/>
					<div style={mainButtonText}>New<br/>Product</div>
				</Button>
				<Button variant={"outline-primary"} style={mainButtonStyle} onClick={promote}>
					<FontAwesomeIcon icon={faHeartCircleBolt} size={"2xl"}/>
					<div style={mainButtonText}>Promote<br/>Product</div>
				</Button>
			</div>

			<div>

				<div className={'accordion'} style={{...customAccordion}} onClick={toggleAccordion}>
					<FontAwesomeIcon style={arrowStyle} icon={faAngleDown}/>
					Dashboard
					<div style={accordionBodyStyle} className={'shadow'}>

						<img src={user.picture_url} className='picture-preview mb-4' alt='Dashboard Picture'/>

						<div style={profileContentStyle}>
							<b>Username</b>
							<div>
								{user.user_username}
							</div>
						</div>
						<div style={profileContentStyle}>
							<b>Name</b>
							<div>
								{user.user_full_name}
							</div>
						</div>

						{
							user.user_whatsapp &&
							<div style={profileContentStyle}>
								<b>Whatsapp</b>
								<div>
									{user.user_whatsapp}
								</div>
							</div>
						}

						{
							user.user_telegram &&
							<div style={profileContentStyle}>
								<b>Telegram</b>
								<div>
									{user.user_telegram}
								</div>
							</div>
						}

						{
							user.user_instagram &&
							<div style={profileContentStyle}>
								<b>Instagram</b>
								<div>
									{user.user_instagram}
								</div>
							</div>
						}

						{
							user.user_custom_url &&
							<div style={profileContentStyle}>
								<b>Custom Link</b>
								<div>
									{user.user_custom_url}
								</div>
							</div>
						}

						<div className="d-block text-center" style={profileContentStyle}>
							{
								user.user_privacy === 0
									?
									'Anyone can contact you'
									:
									user.user_privacy === 1
										?
										'Only logged in users can contact you'
										:
										<div className="text-danger">
											You have hidden your contact details
										</div>
							}
						</div>

						{
							!user.user_whatsapp && !user.user_telegram && !user.user_instagram
							&&
							<div className="d-block text-center" style={profileContentStyle}>
								<div className="text-danger">
									You do not have any contact details
								</div>
								<div>
									Select <b>Edit Profile</b> to add contact details
								</div>
							</div>
						}

					</div>
				</div>

				<Toast className="mb-3" onClose={() => setShowCommunitySaved(false)} show={showCommunitySaved}
				       delay={5000} autohide>
					<Toast.Header>
						<strong className="me-auto">Saved your Community preference</strong>
					</Toast.Header>
					<Toast.Body>Your product listings will only be shown under the selected communities</Toast.Body>
				</Toast>

				<CommunitySelector
					user={user}
					folded={false}
					location={location}
					submitFn={(_) => setShowCommunitySaved(true)}
				/>


				<div className={'accordion'} style={customAccordion} onClick={toggleAccordion}>
					<FontAwesomeIcon style={arrowStyle} icon={faAngleDown}/>
					Your Products
					<div style={{
						margin: '10px 0',
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'center',
						gap: '10px'
					}}>
						{
							items.map((i) => {
								return <ItemCard item={i}/>
							})
						}

					</div>
				</div>

			</div>

			<Modal show={showNotLogin} onHide={closeAllConfirmations}>
				<Modal.Header closeButton>
					<Modal.Title>You cannot access the Dashboard</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						'You need to login to access your Dashboard. ' +
						'You will be redirected to the login page now.'
					}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={closeAllConfirmations} variant="secondary">
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	)
}

export default Dashboard;