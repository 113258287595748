import React, {useEffect, useState} from 'react';
import {Alert, Button, Container, Form} from "react-bootstrap";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {ProgressBar} from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Constants from "../classes/Constants";
import {faExternalLinkAlt, faTimes} from "@fortawesome/free-solid-svg-icons";

function EditContact({user}) {

	const navigate = useNavigate();

	// Handles the contact methods form
	// [0]: form name
	// [2]: title
	// [3]: description
	const [contactMethods, setContactMethods] = useState([
		['whatsapp', 'WhatsApp', 'Begins with + and country code'],
		['telegram', 'Telegram', 'Ensure this is a valid Telegram username'],
		['instagram', 'Instagram', 'Ensure this is a valid Instagram username'],
		['custom', 'Custom Link', 'Enter your own custom Link'],
	]);

	const [isLoading, setIsLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [formData, setFormData] = useState({
		session: user.session,
		action: 'modifyContact',
		privacy: user.user_privacy,
		whatsapp: user.user_whatsapp,
		telegram: user.user_telegram,
		instagram: user.user_instagram,
		custom: user.user_custom_url,
	});


	useEffect(() => {
		if (user.user_id === undefined) {
			navigate('/');
		}
	}, [])

	function handleInputChange(event) {
		const {name, value} = event.target;
		setFormData(prevData => ({
			...prevData,
			[name]: value,
		}));
	}

	function testContactMethod(method) {
		switch (method) {
			case 'whatsapp':
				window.open('https://wa.me/' + formData[method]);
				break;
			case 'telegram':
				window.open('https://t.me/' + formData[method]);
				break;
			case 'instagram':
				window.open('https://instagram.com/' + formData[method]);
				break;
			case 'custom':
				const url = standardizeUrl(formData[method]);
				if (url) window.open(url);
				else {
					setAlertMessage('Your Link must start with HTTP or HTTPS');
				}
				break;
		}
	}


	/**
	 * Validates and standardizes a user input URL.
	 * @param {string} inputUrl - The user input URL.
	 * @returns {string|null} - The standardized URL if valid, null otherwise.
	 */
	function standardizeUrl(inputUrl) {
		try {
			// Create a URL object
			const url = new URL(inputUrl);

			// If no protocol is provided, assume http (you can also assume https)
			if (!url.protocol) {
				url.protocol = 'http:';
			}

			// Standardize: ensure URLs have trailing slash
			if (!url.pathname || url.pathname === '/') {
				url.pathname = '/';
			} else if (!url.pathname.endsWith('/')) {
				url.pathname += '/';
			}

			// Return the standardized URL
			return url.toString();
		} catch (e) {
			console.error(e.message);
			return null;
		}
	}

	/**
	 * Makes the input and formData empty for the corresponding contact method
	 * @param index
	 */
	function clearContactMethod(index) {
		const method = contactMethods[index][0];
		const input = document.querySelector(`input[name=${method}]`);
		input.value = '';
		setFormData(prevData => ({
			...prevData,
			[method]: '',
		}));
	}

	async function handleSubmit(e) {
		e.preventDefault();
		setAlertMessage('');
		if (isLoading) return;

		setIsLoading(true);
		const response = await axios.post(Constants.API + 'user.php', formData);
		setIsLoading(false);

		const status = response.data.status;
		const result = response.data.result;

		if (status) {
			window.location.href = '/dashboard';
		} else {
			setAlertMessage(result);
		}
	}

	return (
		<Container>
			<div className='header-box shadow'>
				<h2>Contact Details</h2>
				<br/>
				<small>Choose how others contact you</small>
			</div>

			<Form method='post' onSubmit={handleSubmit} className='my-3 p-4 shadow header-box'>

				<Form.Group className="mb-3">
					<Form.Label>Who can contact</Form.Label>
					<Form.Select name='privacy' defaultValue={user.user_privacy} onChange={handleInputChange}>
						<option value={0}>Anyone</option>
						<option value={1}>Only logged in users</option>
						<option value={2}>No one</option>
					</Form.Select>

					{
						formData.privacy == 2
							?
							<Form.Text className="text-danger">
								No one can contact you. Useful for temporarily deactivating your account.
							</Form.Text>
							:
							''
					}
				</Form.Group>


				{
					contactMethods.map((method, index) =>
						<Form.Group className="mb-3" key={index}>
							<Form.Label>{method[1]}</Form.Label>
							<div className="d-flex gap-3">
								{
									// Show the button to remove the contact method
									// Only show the button if the contact method is not empty
									formData[method[0]] &&
									<Button variant="danger" onClick={() => clearContactMethod(index)}>
										<FontAwesomeIcon icon={faTimes}/>
									</Button>
								}
								<Form.Control name={method[0]} type="text"
								              defaultValue={formData[method[0]]}
								              onChange={handleInputChange}
								              isValid={!!formData[method[0]]}
								/>
								{
									// Button to open a link to test the contact method
									// Only show the button if the contact method is not empty
									formData[method[0]] &&
									<Button variant="secondary" onClick={() => testContactMethod(method[0])}>
										<FontAwesomeIcon icon={faExternalLinkAlt}/>
									</Button>
								}

							</div>
							<Form.Text className="text-muted">
								{method[2]}
							</Form.Text>
						</Form.Group>
					)
				}

				<ProgressBar
					height="50"
					width="60"
					ariaLabel="progress-bar-loading"
					wrapperStyle={{}}
					wrapperClass="progress-bar-wrapper"
					borderColor='#F4442E'
					barColor='#51E5FF'
					visible={isLoading}
				/>
				<br/>
				<Button type="submit">
					Submit
				</Button>
			</Form>

			{
				alertMessage
					?
					<Alert variant='danger'>
						{alertMessage}
					</Alert>
					:
					''
			}
		</Container>
	)
}

export default EditContact;