import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {RotatingSquare} from "react-loader-spinner";
import axios from "axios";
import './styles/PictureSelector.css';
import Constants from "../classes/Constants";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";


function PictureSelector({user, item, allowEdit}) {

	const maxPictures = 5;
	const [previewPictures, setPreviewPictures] = useState([]);
	const [previewPicturesUploading, setPreviewPicturesUploading] = useState([]);
	const [picturesId, setPicturesId] = useState([]);
	const [selectedPreview, setSelectedPreview] = useState(0);
	const [showDeletionModal, setShowDeletionModal] = useState(false);

	useEffect(() => {
		let _pictures = [...item.pictures]

		// If only one default picture (i.e. no uploaded pictures since default is provided by server)
		// Then remove and make empty array
		if (_pictures.length === 1 && _pictures[0].includes("default.jpeg")) {
			_pictures = [];
		}
		setPreviewPictures(_pictures);
		setPicturesId(_pictures);
		setPreviewPicturesUploading(_pictures.map(_ => false));

	}, [])

	function onChange(e) {
		if (!e.target.files || e.target.files.length === 0) {
			return
		}

		const picture = e.target.files[0];
		const objectUrl = URL.createObjectURL(picture);
		setPreviewPictures([...previewPictures, objectUrl]);
		setPreviewPicturesUploading([...previewPicturesUploading, true])

		const newUploadingIndex = previewPicturesUploading.length;

		const data = new FormData();
		data.append('action', 'addPicture');
		data.append('session', user.session);
		data.append('itemId', item.item_id);
		data.append('picture', picture);
		axios.post(Constants.API + 'item.php', data,
			{headers: {'Content-Type': 'multipart/form-data'}})
			.then(response => {
				const status = response.data.status;
				const id = status ? parseInt(response.data.result) : null;
				const _uploading = [...previewPicturesUploading];
				_uploading[newUploadingIndex] = false;

				setPreviewPicturesUploading(_uploading);
				setPicturesId([...picturesId, id]);
			});
	}


	function removePicture(index) {

		setShowDeletionModal(false);

		const data = {
			action: 'removePicture',
			session: user.session,
			itemId: item.item_id,
			pictureId: picturesId[index]
		}
		axios.post(Constants.API + 'item.php', data).then(response => {
			if (response.data.status) {
				removePicturePreview(index);
			}
		})
	}

	function removePicturePreview(index) {
		// Update array
		const _pictures = previewPictures.filter((_, i) => i !== index);
		setPreviewPictures(_pictures);

		// Update loading array
		const _picturesUploading = previewPicturesUploading.filter((_, i) => i !== index);
		setPreviewPicturesUploading(_picturesUploading);

		// Update pictures ID
		const _picturesId = picturesId.filter((_, i) => i !== index);
		setPicturesId(_picturesId);

		if (selectedPreview >= previewPictures.length - 1) {
			setSelectedPreview(0);
		}
	}

	return (
		<div className="mb-5">
			<div>
				{
					allowEdit
						?
						`Upload pictures (Maximum ${maxPictures})`
						:
						''
				}

			</div>
			{
				previewPicturesUploading.some(Boolean) ?
					<small className='text-danger'>Do not SUBMIT while uploading</small>
					:
					''
			}
			<div className="preview">
				<div className={"scroll-preview" + (!allowEdit ? ' centralize' : '')}>

					{
						previewPictures.map((p, i) =>
							<div className={"mini-preview" + (selectedPreview === i ? " selected" : "")}>
								<img
									src={p} alt='Mini preview'
									onClick={() => setSelectedPreview(i)}
								/>

								{
									!allowEdit
										? ''
										:
										previewPicturesUploading[i]
											?
											<RotatingSquare
												height="50"
												width="50"
												color="red"
												ariaLabel="rotating-square-loading"
												strokeWidth="4"
												wrapperStyle={{}}
												wrapperClass=""
												visible={true}
											/>
											:
											<Button variant="outline-danger" onClick={() => {
												setSelectedPreview(i);
												setShowDeletionModal(true);
											}}>
												<FontAwesomeIcon icon={faTrashCan}/>
											</Button>
								}
							</div>
						)
					}

				</div>

				{
					!(previewPictures.length >= maxPictures || !allowEdit) &&
					<div className="picture-select-input">
						<label htmlFor="picture" className="mini-preview">
						</label>
						<input
							title="Picture"
							placeholder="Upload a picture"
							onChange={onChange}
							name='picture'
							type="file"
							accept="image/*"/>
					</div>
				}
				<img className={"main-preview " + (!allowEdit ? 'big-preview' : '')}
				     src={selectedPreview < previewPictures.length
					     ? previewPictures[selectedPreview]
					     : Constants.DEFAULT_ITEM_IMAGE}
				     alt='Profile preview'/>
			</div>


			<Modal show={showDeletionModal} onHide={() => setShowDeletionModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to delete this picture? You cannot undo this action.
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => removePicture(selectedPreview)} variant="danger">
						Delete
					</Button>
				</Modal.Footer>
			</Modal>

		</div>
	);
}

export default PictureSelector;