import React from "react";

function Checkbox({label, checked, onChangeFn}) {
	return <div className="d-flex align-items-center">
		<div className="custom-toggle" style={{scale: "0.5"}}>
			<input type="checkbox" id="toggle" defaultChecked={checked}
			       onChange={(e) => onChangeFn(e.target.checked)}/>
			<label className="shadow" htmlFor="toggle"></label>
		</div>
		<div className="fw-bold">
			{label}
		</div>
	</div>;
}

export default Checkbox;
