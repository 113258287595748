// ItemPage.js
import React, {useEffect, useState} from 'react';
import './styles/ItemPage.css';
import {useNavigate, useParams} from "react-router-dom";
import PictureSelector from "./PictureSelector";
import {Badge, Button, Modal, Toast} from "react-bootstrap";
import UserCard from "./UserCard";
import axios from "axios";
import Checkbox from "./Checkbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import Constants from "../classes/Constants";

function ItemPage({user}) {

	const navigate = useNavigate();
	const {item_id} = useParams();

	const [currentItem, setCurrentItem] = useState({});
	const [isOwner, setIsOwner] = useState(false);
	const [soldCheck, setSoldCheck] = useState(false);
	const [communitiesInfo, setCommunitiesInfo] = useState([]);

	const [showRemoveItemConfirmation, setShowRemoveItemConfirmation] = useState(false);
	const [showReportModal, setShowReportModal] = useState(false);
	const [showReportToast, setShowReportToast] = useState(false);

	useEffect(() => {
		if (!item_id) {
			navigate('/');
			return;
		}

		loadItem()
			.then(getCommunitiesInfo);

	}, []);


	/**
	 * Load the item from the item_id
	 */
	async function loadItem() {
		const data = {
			action: 'one',
			itemId: item_id,
			session: user.session
		}
		const response = await axios.post(Constants.API + 'item.php', data);
		const status = response.data.status;
		const result = response.data.result;
		if (!status) {
			console.error("Could not retrieve item", response.data);
			return;
		}

		const _item = result;

		if (!_item) {
			navigate('/');
			return;
		}

		setIsOwner(_item.is_owner);
		setCurrentItem(_item);
		return _item;
	}


	function daysAgoOrDate(dateString) {
		const inputDate = new Date(dateString);
		const currentDate = new Date();

		// Set both dates to start of the day for accurate comparison
		inputDate.setHours(0, 0, 0, 0);
		currentDate.setHours(0, 0, 0, 0);

		const oneDayMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
		const diffDays = (currentDate - inputDate) / oneDayMilliseconds;

		if (diffDays < 1) {
			return 'today';
		}

		if (diffDays > 0 && diffDays < 10) {
			return `${diffDays} days ago`;
		}

		return `on ${inputDate.toLocaleDateString()}`;
	}


	/**
	 * Get the item owner's communities
	 */
	async function getCommunitiesInfo(item) {
		const communities = item.communities;
		if (!communities || communities.length === 0) {
			return;
		}

		const data = {action: 'getSome', communities: communities.join(',')};
		const request = await axios.post(Constants.API + 'community.php', data);
		const status = request.data.status;
		const result = request.data.result;
		if (!status) {
			console.error("Could not retrieve communities", request.data);
			return;
		}
		setCommunitiesInfo(result);
	}


	/**
	 * Go to the edit item page
	 */
	function editItemPage() {
		navigate(`/product/modify/${item_id}`)
	}

	/**
	 * Show the remove item confirmation modal
	 */
	function removeItemConfirmation() {
		setSoldCheck(false);
		setShowRemoveItemConfirmation(true);
	}

	/**
	 * Show the report item confirmation modal
	 */
	function reportItemConfirmation() {
		setShowReportModal(true);
	}

	/**
	 * Submit the report of an item
	 */
	async function submitReport() {
		setShowReportModal(false);
		const data = {
			action: 'report',
			itemId: item_id
		}
		const response = await axios.post(Constants.API + 'item.php', data);
		if (response.data.status) {
			setShowReportToast(true);
		}
	}


	async function submitRemoveItem() {
		const data = {
			action: 'remove',
			session: user.session,
			itemId: item_id,
			sold: soldCheck
		};
		const response = await axios.post(Constants.API + 'item.php', data);
		if (response.data.status) {
			navigate('/dashboard');
		}
	}


	function closeAllConfirmations() {
		setShowRemoveItemConfirmation(false);
		setShowReportModal(false);
	}

	return !currentItem || !currentItem.item_id
		?
		<div className="container item-page loading">
		</div>
		:
		<div className="container item-page">
			<div className="main-content">
				<div className="box">
					<PictureSelector item={currentItem} user={user} allowEdit={false}/>
				</div>
				<div className="box">
					<Button onClick={reportItemConfirmation} className="report-button" variant="outline-secondary">
						<FontAwesomeIcon icon={faExclamationCircle} size="lg"/>
					</Button>
					<Toast className="mb-3" onClose={() => setShowReportToast(false)} show={showReportToast}
					       delay={5000} autohide>
						<Toast.Header>
							<strong className="me-auto">Report Successful</strong>
						</Toast.Header>
						<Toast.Body>Your report has been submitted</Toast.Body>
					</Toast>
					<Badge className="item-price">
						<sup>{Constants.GET_CURRENCY(currentItem.community_country)} </sup>
						{currentItem.item_price}
					</Badge>
					<div className="fw-bold title">{currentItem.item_title}</div>
					<div className="description">{currentItem.item_description}</div>
					<div className="date">Listed {daysAgoOrDate(currentItem.item_list_date)}</div>
					{
						communitiesInfo.length > 0 &&
						<div className="d-flex gap-1 flex-wrap">
							{
								communitiesInfo.map((community, index) => {
									return <Badge key={index}
									              bg={"secondary"}
									              className="community-badge">{community.community_small_name}</Badge>

								})
							}
						</div>
					}
					<hr/>
					{
						isOwner &&
						<div className="d-flex align-items-end justify-content-between mb-3">
							<Badge>You own this product</Badge>
							<div className="d-flex gap-2">
								<Button variant="secondary" onClick={editItemPage}>Edit</Button>
								<Button variant="secondary" onClick={removeItemConfirmation}>Remove</Button>
							</div>
						</div>
					}
					<UserCard user={currentItem} loggedInUser={user}/>
				</div>
			</div>

			<Modal show={showRemoveItemConfirmation} onHide={closeAllConfirmations}>
				<Modal.Header closeButton>
					<Modal.Title>Remove {currentItem.item_title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						'Are you sure you want to remove the listing for this product? ' +
						'You cannot undo this action.'
					}

					<Checkbox checked={soldCheck}
					          onChangeFn={setSoldCheck}
					          label={'I have sold this product'}/>


				</Modal.Body>
				<Modal.Footer>
					<Button onClick={submitRemoveItem} variant="secondary">
						Remove
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showReportModal} onHide={closeAllConfirmations}>
				<Modal.Header closeButton>
					<Modal.Title>Report {currentItem.item_title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Submit a report if you think this product is inappropriate. We will review it as soon as
					possible.
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={submitReport} variant="danger">
						Report
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
}

export default ItemPage;
