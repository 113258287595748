import React, {useEffect, useState} from 'react';
import {Alert, Button, Container, Dropdown, Form, InputGroup} from "react-bootstrap";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {ProgressBar} from "react-loader-spinner";
import PictureSelector from "./PictureSelector";
import Constants from "../classes/Constants";

function EditItem({user}) {

	const navigate = useNavigate();
	const {item_id} = useParams();

	const listingTypes = Constants.ITEM_LISTING_TYPES;
	const [alertMessage, setAlertMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [currentItem, setCurrentItem] = useState(null);
	const [formData, setFormData] = useState({
		action: 'modify',
		title: '',
		description: '',
		price: 0.0,
		type: 0
	});


	useEffect(() => {
		if (!user.user_id || !item_id) {
			navigate('/');
			return;
		}

		loadItem();
	}, []);


	/**
	 * Load the item from the item_id
	 */
	async function loadItem() {
		const data = {
			action: 'one',
			itemId: item_id,
			session: user.session
		}
		const response = await axios.post(Constants.API + 'item.php', data);
		const status = response.data.status;
		const result = response.data.result;
		if (!status) {
			console.error("Could not retrieve item", response.data);
			return;
		}

		const _item = result;

		if (!_item || !_item.is_owner) {
			navigate('/');
			return;
		}

		setCurrentItem(_item);

		setFormData({
			...formData,
			session: user.session,
			itemId: item_id,
			title: _item.item_title,
			description: _item.item_description,
			price: _item.item_price,
			type: _item.item_list_type
		})
	}

	const handleInputChange = event => {
		const {name, value} = event.target;
		setFormData(prevData => ({
			...prevData,
			[name]: value,
		}));
	};

	async function handleSubmit(e) {
		e.preventDefault();
		clearErrors();
		if (isLoading) return;

		const description = formData.description;
		const price = parseFloat(formData.price);

		const target = e.target;
		const descElem = target[1];
		const priceElem = target[2];

		// Check description
		if (description.length > 200) {
			descElem.classList.add('is-invalid');
			setAlertMessage('Description is too long');
			return;
		}

		// Check price
		if (price > 10000) {
			priceElem.classList.add('is-invalid');
			setAlertMessage('Price is too... pricey');
			return;
		}
		if (price < 0) {
			priceElem.classList.add('is-invalid');
			setAlertMessage('Price cannot be negative');
			return;
		}

		setIsLoading(true);
		const response = await axios.post(Constants.API + 'item.php', formData);
		setIsLoading(false);

		if (response.data.status) {
			navigate('/dashboard');
		} else {
			setAlertMessage('Could not modify your product! Try again later.')
		}
	}

	function clearErrors() {
		const formElems = document.getElementsByClassName('form-control');
		for (const e of formElems) {
			e.classList.remove('is-invalid');
		}

		setAlertMessage('');
	}

	return (
		<Container>
			<div className='header-box shadow'>
				<h2>Edit Product</h2><br/>
				<small>Make changes to your product</small>
			</div>

			{
				!currentItem
					?
					''
					:
					<Form onSubmit={handleSubmit} className='my-3 p-4 shadow header-box'>
						<Form.Group className="mb-3">
							<Form.Label>Title</Form.Label>
							<Form.Control name='title' type="text" defaultValue={currentItem.item_title} required
							              onChange={handleInputChange}
							              disabled/>
							<Form.Text className="text-muted">
								You cannot change the title
							</Form.Text>
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Description</Form.Label>
							<Form.Control
								name='description'
								as="textarea"
								style={{height: '100px'}}
								defaultValue={currentItem.item_description}
								required
								onChange={handleInputChange}
							/>
							<Form.Text className="text-muted">
								Give a brief description
							</Form.Text>
						</Form.Group>

						<InputGroup className="mb-3">
							<InputGroup.Text>{Constants.GET_CURRENCY(currentItem.community_country)}</InputGroup.Text>
							<Form.Control defaultValue={currentItem.item_price}
							              type="number"
							              name='price'
							              placeholder="Price"
							              step="0.01"
							              onChange={handleInputChange}
							              required/>
						</InputGroup>


						<Form.Group className="mb-3">
							<Form.Label>Type of Listing</Form.Label>
							<Dropdown>
								<Dropdown.Toggle variant="secondary" id="dropdown-basic">
									{listingTypes[formData.type]}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{
										listingTypes.map((type, index) => (
											<Dropdown.Item
												key={index}
												onClick={() => setFormData({...formData, type: index})}
											>
												{type}
											</Dropdown.Item>
										))
									}
								</Dropdown.Menu>
							</Dropdown>
						</Form.Group>

						<PictureSelector user={user} item={currentItem} allowEdit={true}/>

						<ProgressBar
							height="50"
							width="60"
							ariaLabel="progress-bar-loading"
							wrapperStyle={{}}
							wrapperClass="progress-bar-wrapper"
							borderColor='#F4442E'
							barColor='#51E5FF'
							visible={isLoading}
						/>
						<br/>
						<Button type="submit">
							Submit
						</Button>
					</Form>
			}


			{
				alertMessage === '' ? '' : (
					<Alert variant='danger'>
						{alertMessage}
					</Alert>
				)
			}
		</Container>
	)
}

export default EditItem;